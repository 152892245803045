import { BlockNoteView } from "@blocknote/mantine";
import { useCreateBlockNote } from "@blocknote/react";
import { useEffect, useState } from "react";

import "@blocknote/mantine/style.css";
import "@blocknote/core/fonts/inter.css";
import { BlockNoteSchema, createInlineContentSpec } from "@blocknote/core";
import { useMutation } from "@tanstack/react-query";
import { toast } from "components/hooks/use-toast";
import { Button } from "components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from "components/ui/dialog";
import { Separator } from "components/ui/separator";
import { Skeleton } from "components/ui/skeleton";
import { convertHTMLToPlainText } from "lib/utils";
import { FileText, Share } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import { appConfig } from "../config";
import { type BenchmarkResult, ReadabilityCard } from "../readability-card";
import AIContentDetection from "./ai-content-detection";

type Props = {
	initialContent: string;
	onContentChange?: (content: string) => void;
	onReadabilityMetricsChange?: (metrics: BenchmarkResult[]) => void;
	onExportToWordPress?: () => void;
};

const TextEditorWithReadability = ({
	initialContent,
	onContentChange,
	onReadabilityMetricsChange,
	onExportToWordPress,
}: Props) => {
	const [currentContent, setCurrentContent] = useState(
		convertHTMLToPlainText(initialContent),
	);
	const [showExportModal, setShowExportModal] = useState(false);

	const editor = useCreateBlockNote();
	const posthog = usePostHog();

	const {
		data: benchmarkResults,
		isPending: isBenchmarkResultsPending,
		mutate: mutateBenchmarkResults,
	} = useMutation({
		mutationFn: async () => {
			try {
				const result = await fetch(
					`${appConfig.API_MAIN_URL}/api/readability/benchmarks`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({ text: currentContent }),
					},
				);

				const benchmarks: BenchmarkResult[] | undefined = await result.json();

				if (posthog) {
					posthog.capture("Readability Benchmark", {
						text: currentContent.substring(0, 100),
						results: benchmarks?.map(({ name, value }) => ({ name, value })),
					});
				}

				if (onReadabilityMetricsChange && benchmarks) {
					onReadabilityMetricsChange(benchmarks);
				}

				return benchmarks;
			} catch (error) {
				return [];
			}
		},
	});

	useEffect(() => {
		async function loadInitialStreamResponse() {
			const blocks = await editor.tryParseHTMLToBlocks(initialContent);
			editor.replaceBlocks(editor.document, blocks);
		}
		loadInitialStreamResponse();

		mutateBenchmarkResults();
	}, [initialContent, editor, mutateBenchmarkResults]);

	const getLatestContentInPlainText = async () => {
		const html = await editor.blocksToHTMLLossy(editor.document);
		const plainText =
			convertHTMLToPlainText(html) || convertHTMLToPlainText(initialContent);
		return plainText;
	};

	return (
		<div className="relative">
			<div className="sticky top-0 z-50 bg-background border-b">
				<div className="container mx-auto py-2 px-4 flex justify-between items-center">
					<h2 className="text-lg font-semibold">Editor de Contenido</h2>
					<Button
						onClick={() => setShowExportModal(true)}
						variant="default"
						className="flex items-center gap-2 bg-blue-500 hover:bg-blue-600 text-white font-medium shadow-sm transition-colors"
					>
						<Share className="w-4 h-4" />
						Exportar
					</Button>
				</div>
			</div>

			<Dialog open={showExportModal} onOpenChange={setShowExportModal}>
				<DialogContent className="sm:max-w-[425px]">
					<DialogHeader>
						<DialogTitle>Exportar Contenido</DialogTitle>
						<DialogDescription>
							Elige el formato en el que deseas exportar tu contenido
						</DialogDescription>
					</DialogHeader>
					<div className="grid gap-4 py-4">
						<Button
							onClick={async () => {
								const content = await getLatestContentInPlainText();
								navigator.clipboard.writeText(content);
								toast({
									title: "Contenido copiado",
									description: "El contenido ha sido copiado al portapapeles",
								});
								setShowExportModal(false);
							}}
							variant="outline"
							className="w-full justify-start"
						>
							<FileText className="w-4 h-4 mr-2" />
							Copiar Texto Plano
						</Button>

						<Button
							onClick={async () => {
								const html = await editor.blocksToHTMLLossy(editor.document);
								navigator.clipboard.writeText(html);
								toast({
									title: "Contenido copiado",
									description:
										"El contenido con formato ha sido copiado al portapapeles",
								});
								setShowExportModal(false);
							}}
							variant="outline"
							className="w-full justify-start"
						>
							<FileText className="w-4 h-4 mr-2" />
							Copiar con Formato
						</Button>

						<Button
							onClick={async () => {
								const html = await editor.blocksToHTMLLossy(editor.document);
								const blob = new Blob([html], {
									type: "text/html",
								});
								const url = window.URL.createObjectURL(blob);
								const a = document.createElement("a");
								a.href = url;
								a.download = "blog-content.html";
								a.click();
								setShowExportModal(false);
							}}
							variant="outline"
							className="w-full justify-start"
						>
							<FileText className="w-4 h-4 mr-2" />
							Exportar HTML
						</Button>
						<Separator className="my-2" />
					</div>
				</DialogContent>
			</Dialog>

			<div className="container mx-auto px-4">
				<section className="grid gap-4 my-5 md:grid-cols-3">
					{isBenchmarkResultsPending ? (
						<>
							<Skeleton className="h-96" />
							<Skeleton className="h-96" />
							<Skeleton className="h-96" />
						</>
					) : (
						benchmarkResults?.map(({ name, description, value, maxValue }) => (
							<ReadabilityCard
								name={name}
								description={description}
								value={value}
								maxValue={maxValue}
								refreshAction={() => mutateBenchmarkResults()}
								key={name}
							/>
						))
					)}
				</section>
				<section>
					<AIContentDetection text={currentContent} />
				</section>
				<BlockNoteView
					editor={editor}
					className="unreset"
					onChange={async () => {
						const html = await editor.blocksToHTMLLossy(editor.document);

						if (onContentChange) {
							onContentChange(html);
						}

						const plainText = await getLatestContentInPlainText();
						setCurrentContent(plainText);
					}}
				/>
			</div>
		</div>
	);
};

export default TextEditorWithReadability;
