import { Badge } from "components/ui/badge";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "components/ui/tooltip";
import { differenceInDays, formatDistanceToNow } from "date-fns";
import { AlertCircle, AlertTriangle, CircleCheck, XCircle } from "lucide-react";

const SyncStatusBadge = ({ syncStatus, lastSyncDate, lastSuccessDate }) => {
	const formatTooltip = (date, isError = false) => {
		if (!date) return "Never synced";
		if (isError && lastSuccessDate) {
			return `Sync failed, last successful sync ${formatDistanceToNow(new Date(lastSuccessDate), { addSuffix: true })}`;
		}
		return `Last synced ${formatDistanceToNow(new Date(date), { addSuffix: true })}`;
	};

	const daysSinceLastSuccessSync = lastSuccessDate
		? differenceInDays(new Date(), new Date(lastSuccessDate))
		: null;

	const daysSinceLastSync = differenceInDays(
		new Date(),
		new Date(lastSyncDate),
	);

	const renderBadge = (icon, variant, isError = false) => (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger>
					<Badge variant={variant}>{icon}</Badge>
				</TooltipTrigger>
				<TooltipContent>
					<p>{formatTooltip(lastSyncDate, isError)}</p>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);

	if (syncStatus === "Never synced" || !lastSyncDate) {
		return renderBadge(<XCircle className="w-4 h-4" />, "destructive");
	}

	if (syncStatus === "error" && daysSinceLastSuccessSync !== null) {
		if (daysSinceLastSuccessSync < 1) {
			return renderBadge(
				<AlertTriangle className="w-4 h-4" />,
				"success",
				true,
			);
		}
		if (daysSinceLastSuccessSync < 3) {
			return renderBadge(
				<AlertTriangle className="w-4 h-4" />,
				"warning",
				true,
			);
		}
		return renderBadge(
			<AlertCircle className="w-4 h-4" />,
			"destructive",
			true,
		);
	}

	if (syncStatus === "success") {
		if (daysSinceLastSync < 1) {
			return renderBadge(<CircleCheck className="w-4 h-4" />, "success");
		}
		if (daysSinceLastSync < 3) {
			return renderBadge(<AlertCircle className="w-4 h-4" />, "warning");
		}
		return renderBadge(<AlertCircle className="w-4 h-4" />, "destructive");
	}

	return renderBadge(<XCircle className="w-4 h-4" />, "destructive");
};

export default SyncStatusBadge;
