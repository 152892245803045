import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { Button } from "components/ui/button";
import { Settings, Loader2 } from "lucide-react";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from "components/ui/form";
import { Textarea } from "components/ui/textarea";
import { useIcpForm } from "../_hooks/use-icp-form";
import { FormLabelWithTooltip } from "components/form/form-label-with-tooltip";

interface IcpSettingsDialogProps {
  userId: string;
  token: string;
}

export const IcpSettingsDialog = ({ userId, token }: IcpSettingsDialogProps) => {
  const { form, settingsLoading, handleSubmit } = useIcpForm(userId, token);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">
          <Settings className="w-4 h-4 mr-2" />
          ICP Settings
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-[600px]">
        <DialogHeader>
          <DialogTitle>ICP Configuration</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="icp_description"
              render={({ field }) => (
                <FormItem>
                  <FormLabelWithTooltip 
                    label="Description"
                    tooltip="General description of your ideal customer profile, including key characteristics and pain points."
                  />
                  <FormControl>
                    <Textarea {...field} placeholder="Enter ICP description..." />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="icp_target_industries"
              render={({ field }) => (
                <FormItem>
                  <FormLabelWithTooltip 
                    label="Target Industries"
                    tooltip="Specific industries or business sectors your product/service is best suited for."
                  />
                  <FormControl>
                    <Textarea {...field} placeholder="Enter target industries..." />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="icp_target_roles"
              render={({ field }) => (
                <FormItem>
                  <FormLabelWithTooltip 
                    label="Target Roles"
                    tooltip="Job titles, positions, or decision-makers you typically sell to."
                  />
                  <FormControl>
                    <Textarea {...field} placeholder="Enter target roles..." />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="icp_company_size"
              render={({ field }) => (
                <FormItem>
                  <FormLabelWithTooltip 
                    label="Company Size"
                    tooltip="Preferred company size range in terms of employees or revenue."
                  />
                  <FormControl>
                    <Textarea {...field} placeholder="Enter company size range..." />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="icp_geography"
              render={({ field }) => (
                <FormItem>
                  <FormLabelWithTooltip 
                    label="Geography"
                    tooltip="Target geographical locations, regions, or markets."
                  />
                  <FormControl>
                    <Textarea {...field} placeholder="Enter target geography..." />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="icp_goals"
              render={({ field }) => (
                <FormItem>
                  <FormLabelWithTooltip 
                    label="Goals"
                    tooltip="What is your goal with these leads? (e.g., recruiting, selling your product/service, partnerships)"
                  />
                  <FormControl>
                    <Textarea {...field} placeholder="Enter ICP goals..." />
                  </FormControl>
                </FormItem>
              )}
            />
            <Button type="submit" disabled={settingsLoading} className="w-full">
              {settingsLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Saving...
                </>
              ) : (
                <>
                  <Settings className="mr-2 h-4 w-4" />
                  Save Settings
                </>
              )}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}; 