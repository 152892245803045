import { zodResolver } from "@hookform/resolvers/zod";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useToast } from "components/hooks/use-toast";
import { Button } from "components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "components/ui/command";
import { FileUploader } from "components/ui/file-uploader";
import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "components/ui/form";
import { Input } from "components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { ScrollArea } from "components/ui/scroll-area";
import { Textarea } from "components/ui/textarea";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "components/ui/tooltip";
import { cn } from "lib/utils";
import { HelpCircle, Loader2, LoaderCircle } from "lucide-react";
import { Check, ChevronsUpDown } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { LinkedInPostCard } from "./components/LinkedInPostCard";
import { LinkedInProfileDisplay } from "./components/LinkedInProfileDisplay";
import type {
	LinkedInPost,
	LinkedInProfileData,
	ProfilePersonaData,
} from "./types";

type Props = {
	t: (key: string) => string;
};

export const ProfilePersonaCreation: React.FC<Props> = ({ t }) => {
	const { toast } = useToast();
	const [isLoading, setIsLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [page, setPage] = useState(1);
	const [selectedPosts, setSelectedPosts] = useState<LinkedInPost[]>([]);
	const [totalPosts, setTotalPosts] = useState<LinkedInPost[]>([]);
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const [selectedUser, setSelectedUser] = useState<UserData | null>(null);

	const formSchema = z.object({
		email: z.string().email(t("profile-persona.email.validation")),
		phoneNumber: z
			.string()
			.regex(/^\+[1-9]\d{10,14}$/, t("profile-persona.phone.validation")),
		linkedinPdf: z
			.instanceof(File)
			.refine(
				(file) => file.type === "application/pdf",
				t("profile-persona.linkedin_pdf.validation"),
			)
			.optional()
			.superRefine((val, ctx) => {
				if (!existingData?.linkedinProfile && !val) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: t("profile-persona.linkedin_pdf.required"),
					});
				}
			}),
		targetAudience: z
			.string()
			.min(1, t("profile-persona.target_audience.validation")),
		onboardingMessage: z.string().optional(),
		linkedinPosts: z
			.array(
				z.object({
					content: z.string().transform((str) => str.replace(/\n/g, "\n")),
				}),
			)
			.min(1, t("profile-persona.linkedin_posts.validation"))
			.optional(),
		metadata: z.string().optional(),
	});

	type FormValues = z.infer<typeof formSchema>;

	const DEFAULT_ONBOARDING_MESSAGE = t(
		"profile-persona.welcome_message.default",
	);

	type UserData = {
		id: string;
		email: string;
		name: string;
	};

	const fetchEmails = async () => {
		const response = await axios.get<UserData[]>("/api/user/emails");
		return response.data;
	};

	const { data: existingData } = useQuery({
		queryKey: ["profilePersonaData", selectedUser?.id],
		queryFn: async () => {
			if (!selectedUser?.id) return null;
			const response = await axios.get<ProfilePersonaData>(
				`/api/linkedin/profile-persona-data/${selectedUser.id}`,
			);
			return response.data;
		},
		enabled: !!selectedUser?.id,
	});

	const form = useForm<FormValues>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: "",
			phoneNumber: "",
			targetAudience: "",
			onboardingMessage: DEFAULT_ONBOARDING_MESSAGE,
			linkedinPosts: [],
			metadata: "",
		},
		context: { existingData },
	});

	const { fields, append, remove } = useFieldArray({
		control: form.control,
		name: "linkedinPosts",
	});

	const { data: emails, isLoading: isLoadingEmails } = useQuery({
		queryKey: ["userEmails"],
		queryFn: fetchEmails,
	});

	const {
		data: topPosts,
		isLoading: isLoadingPosts,
		isFetching: isFetchingPosts,
	} = useQuery({
		queryKey: ["topPosts", selectedUser?.id, page],
		queryFn: async () => {
			if (!selectedUser) return null;
			const response = await axios.get<LinkedInPost[]>(
				`/api/analytics/user/${selectedUser.id}/top-linkedin-posts?limit=5&page=${page}`,
			);
			return response.data;
		},
		enabled: !!selectedUser,
	});

	useEffect(() => {
		setTotalPosts([]);
		setSelectedPosts([]);
		setPage(1);
		const invalidateQueries = async () => {
			if (selectedUser) {
				await queryClient.invalidateQueries({
					queryKey: ["topPosts"],
				});
			}
		};
		invalidateQueries();
	}, [selectedUser, queryClient]);

	useEffect(() => {
		if (topPosts) {
			const newPosts = topPosts.filter(
				(post) =>
					!selectedPosts.some(
						(selected) => selected.linkedin_id === post.linkedin_id,
					) &&
					!totalPosts.some((total) => total.linkedin_id === post.linkedin_id),
			);

			if (newPosts.length > 0) {
				setTotalPosts((prevPosts) => [...prevPosts, ...newPosts]);
			}
		}
	}, [topPosts, selectedPosts, totalPosts.some]);

	useEffect(() => {
		if (existingData) {
			form.setValue("phoneNumber", existingData.phoneNumber);
			form.setValue(
				"targetAudience",
				existingData.linkedinProfile?.target_audience || "",
			);
			form.setValue("metadata", existingData.metadata);

			if (existingData.linkedinPosts?.length > 0) {
				setSelectedPosts(existingData.linkedinPosts);
				setTotalPosts(existingData.linkedinPosts);
			}
		}
	}, [existingData, form]);

	useEffect(() => {
		form.setValue(
			"linkedinPosts",
			selectedPosts.map((post) => ({
				content: post.content || "",
			})),
		);
	}, [selectedPosts, form]);

	const handlePostSelect = (post: LinkedInPost) => {
		setSelectedPosts((prev) => {
			const isSelected = prev.some((p) => p.linkedin_id === post.linkedin_id);
			if (isSelected) {
				return prev.filter((p) => p.linkedin_id !== post.linkedin_id);
			}
			if (prev.length >= 5) {
				return prev;
			}
			return [...prev, post];
		});
	};

	const onSubmit = useCallback(
		async (data: FormValues) => {
			setIsLoading(true);
			try {
				const formData = new FormData();
				formData.append("email", data.email);
				if (selectedUser) {
					formData.append("userId", selectedUser.id);
				}
				formData.append("phoneNumber", data.phoneNumber);
				formData.append("targetAudience", data.targetAudience);
				if (data.onboardingMessage && data.onboardingMessage.trim() !== "") {
					formData.append("onboardingMessage", data.onboardingMessage);
				}
				if (data.linkedinPosts && data.linkedinPosts.length > 0) {
					formData.append("linkedinPosts", JSON.stringify(data.linkedinPosts));
				}
				if (data.metadata && data.metadata.trim() !== "") {
					formData.append("metadata", data.metadata);
				}

				const headers: Record<string, string> = {};
				if (data.linkedinPdf) {
					formData.append("linkedinPdf", data.linkedinPdf);
					headers["Content-Type"] = "multipart/form-data";
				}

				const response = await axios.post(
					"/api/linkedin/create-profile-persona-and-chatbot-user",
					formData,
					{
						headers,
					},
				);

				toast({
					title: t("profile-persona.toast.success.title"),
					description: response.data.message,
				});

				navigate("/dashboard/content-creation");
			} catch (error) {
				if (axios.isAxiosError(error) && error.response?.status === 404) {
					toast({
						title: t("profile-persona.toast.error.title"),
						description: t("profile-persona.toast.error.user_not_found"),
						variant: "destructive",
					});
				} else {
					toast({
						title: t("profile-persona.toast.error.title"),
						description: t("profile-persona.toast.error.creation_failed"),
						variant: "destructive",
					});
				}
			} finally {
				setIsLoading(false);
			}
		},
		[toast, t, navigate, selectedUser],
	);

	return (
		<Card className="max-w-2xl mx-auto">
			<CardHeader>
				<CardTitle>{t("profile-persona.title")}</CardTitle>
			</CardHeader>
			<CardContent>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
						<FormField
							control={form.control}
							name="email"
							render={({ field }) => (
								<FormItem className="flex flex-col">
									<FormLabel>{t("profile-persona.email.label")}</FormLabel>
									<Popover open={open} onOpenChange={setOpen}>
										<PopoverTrigger asChild>
											<FormControl>
												<Button
													variant="outline"
													role="combobox"
													aria-expanded={open}
													className={cn(
														"w-full justify-between",
														!field.value && "text-muted-foreground",
													)}
													disabled={isLoadingEmails}
												>
													{isLoadingEmails ? (
														<div className="flex items-center">
															<Loader2 className="mr-2 h-4 w-4 animate-spin" />
															{t("profile-persona.email.loading")}
														</div>
													) : (
														field.value ||
														t("profile-persona.email.placeholder")
													)}
													<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
												</Button>
											</FormControl>
										</PopoverTrigger>
										<PopoverContent
											className="w-[--radix-popover-trigger-width] p-0"
											align="start"
											side="bottom"
										>
											<Command>
												<CommandInput
													placeholder={t(
														"profile-persona.email.search_placeholder",
													)}
													className="h-9"
												/>
												<CommandList>
													{isLoadingEmails ? (
														<div className="flex items-center justify-center p-4">
															<Loader2 className="mr-2 h-4 w-4 animate-spin" />
															{t("profile-persona.email.loading")}
														</div>
													) : (
														<>
															<CommandEmpty>
																{t("profile-persona.email.no_results")}
															</CommandEmpty>
															<CommandGroup className="overflow-auto">
																{emails?.map((user) => (
																	<CommandItem
																		key={user.id}
																		value={user.email}
																		onSelect={(currentValue: string) => {
																			form.setValue("email", currentValue);
																			setSelectedUser(
																				emails?.find(
																					(u) => u.email === currentValue,
																				) || null,
																			);
																			setOpen(false);
																		}}
																	>
																		<div className="flex w-full items-center">
																			<div>
																				<div className="text-sm text-muted-foreground">
																					{user.name} ({user.email})
																				</div>
																			</div>

																			<Check
																				className={cn(
																					"ml-auto",
																					user.email === field.value
																						? "opacity-100"
																						: "opacity-0",
																				)}
																			/>
																		</div>
																	</CommandItem>
																))}
															</CommandGroup>
														</>
													)}
												</CommandList>
											</Command>
										</PopoverContent>
									</Popover>
									<FormDescription>
										{t("profile-persona.email.description")}
									</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="phoneNumber"
							render={({ field }) => (
								<FormItem>
									<FormLabel className="flex items-center">
										{t("profile-persona.phone.label")}
										<TooltipProvider>
											<Tooltip>
												<TooltipTrigger asChild>
													<HelpCircle className="w-4 h-4 ml-2 cursor-pointer" />
												</TooltipTrigger>
												<TooltipContent className="bg-white text-black border">
													<p>{t("profile-persona.phone.tooltip.text")}</p>
													<p>{t("profile-persona.phone.tooltip.example")}</p>
													<a
														href="https://faq.whatsapp.com/1294841057948784"
														target="_blank"
														rel="noopener noreferrer"
														className="text-blue-500 hover:underline"
													>
														{t("profile-persona.phone.tooltip.more_info")}
													</a>
													<br />
													<a
														href="https://b_fQwtbAfrP1o.v0.build/"
														target="_blank"
														rel="noopener noreferrer"
														className="text-blue-500 hover:underline"
													>
														{t("profile-persona.phone.tooltip.convert")}
													</a>
												</TooltipContent>
											</Tooltip>
										</TooltipProvider>
									</FormLabel>
									<FormControl>
										<Input
											placeholder={t("profile-persona.phone.placeholder")}
											{...field}
										/>
									</FormControl>
									<FormDescription>
										{t("profile-persona.phone.description")}
									</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						{existingData?.linkedinProfile ? (
							<div className="space-y-4">
								<LinkedInProfileDisplay
									profile={existingData.linkedinProfile}
								/>
								<FormField
									control={form.control}
									name="linkedinPdf"
									render={({ field }) => (
										<FormItem>
											<FormLabel>Update LinkedIn Profile</FormLabel>
											<FormControl>
												<div className="flex items-center gap-2">
													<Button
														type="button"
														variant="outline"
														onClick={() => {
															const input = document.createElement("input");
															input.type = "file";
															input.accept = ".pdf";
															input.onchange = (e) => {
																const file = (e.target as HTMLInputElement)
																	.files?.[0];
																if (file) field.onChange(file);
															};
															input.click();
														}}
													>
														Upload New PDF
													</Button>
													{field.value && (
														<span className="text-sm text-muted-foreground">
															{field.value.name}
														</span>
													)}
												</div>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
						) : (
							<FormField
								control={form.control}
								name="linkedinPdf"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t("profile-persona.linkedin_pdf.label")}
										</FormLabel>
										<FormControl>
											<FileUploader
												value={field.value ? [field.value] : []}
												onValueChange={(newFiles) => {
													field.onChange(
														Array.isArray(newFiles)
															? newFiles[0] || null
															: null,
													);
												}}
												maxFiles={1}
												maxSize={1 * 1024 * 1024} // 1MB
												accept={{ "application/pdf": [".pdf"] }}
											/>
										</FormControl>
										<FormDescription>
											{t("profile-persona.linkedin_pdf.description")}
										</FormDescription>
										<FormMessage />
									</FormItem>
								)}
							/>
						)}

						<FormField
							control={form.control}
							name="targetAudience"
							render={({ field }) => (
								<FormItem>
									<FormLabel>
										{t("profile-persona.target_audience.label")}
									</FormLabel>
									<FormControl>
										<Textarea
											placeholder={t(
												"profile-persona.target_audience.placeholder",
											)}
											{...field}
										/>
									</FormControl>
									<FormDescription>
										{t("profile-persona.target_audience.description")}
									</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="onboardingMessage"
							render={({ field }) => (
								<FormItem>
									<FormLabel>
										{t("profile-persona.welcome_message.label")}
									</FormLabel>
									<FormControl>
										<Textarea
											placeholder={t(
												"profile-persona.welcome_message.placeholder",
											)}
											{...field}
											rows={6}
										/>
									</FormControl>
									<FormDescription>
										{t("profile-persona.welcome_message.description")}
									</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="metadata"
							render={({ field }) => (
								<FormItem>
									<FormLabel>{t("profile-persona.metadata.label")}</FormLabel>
									<FormControl>
										<Textarea
											placeholder={t("profile-persona.metadata.placeholder")}
											{...field}
										/>
									</FormControl>
									<FormDescription>
										{t("profile-persona.metadata.description")}
									</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="linkedinPosts"
							render={({ field }) => (
								<FormItem>
									<FormLabel>
										{t("profile-persona.linkedin_posts.label")}
									</FormLabel>
									<FormDescription className="mb-3">
										{t("profile-persona.linkedin_posts.description")}
										{selectedPosts.length > 0 && (
											<span className="ml-1 font-medium">
												({selectedPosts.length}/5{" "}
												{t("profile-persona.linkedin_posts.selected")})
											</span>
										)}
									</FormDescription>

									<FormControl>
										{!form.watch("email") ? (
											<div className="rounded-md border p-8 text-center text-sm text-muted-foreground">
												{t("profile-persona.linkedin_posts.select_to_view")}
											</div>
										) : (
											<ScrollArea className="h-[400px] rounded-md border p-4">
												{totalPosts?.length ? (
													<>
														<div className="grid grid-cols-2 gap-2">
															{totalPosts.map((post) => (
																<LinkedInPostCard
																	key={post.linkedin_id}
																	post={post}
																	isSelected={selectedPosts.some(
																		(p) => p.linkedin_id === post.linkedin_id,
																	)}
																	onSelect={() => handlePostSelect(post)}
																	disabled={selectedPosts.length >= 5}
																/>
															))}
														</div>

														{topPosts?.length === 5 && (
															<div className="mt-4 text-center">
																<Button
																	type="button"
																	variant="outline"
																	onClick={() => {
																		setPage((prev) => prev + 1);
																	}}
																	disabled={isLoadingPosts}
																>
																	{isFetchingPosts
																		? t(
																				"profile-persona.linkedin_posts.loading",
																			)
																		: t(
																				"profile-persona.linkedin_posts.load_more",
																			)}
																</Button>
															</div>
														)}
													</>
												) : (
													<div className="flex items-center justify-center h-full text-sm text-muted-foreground">
														{t("profile-persona.linkedin_posts.no_posts")}
													</div>
												)}
											</ScrollArea>
										)}
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>

						<Button type="submit" disabled={isLoading}>
							{isLoading ? (
								<>
									<LoaderCircle className="mr-2 h-4 w-4 animate-spin" />
									{t("profile-persona.submit.loading")}
								</>
							) : (
								t("profile-persona.submit.button")
							)}
						</Button>
					</form>
				</Form>
			</CardContent>
		</Card>
	);
};

export default ProfilePersonaCreation;
