import type{ ColumnDef } from "@tanstack/react-table";
import { Button } from "components/ui/button";
import { Building2, MapPin, CheckCircle, ArrowUpDown, HelpCircle, Loader2, LinkedinIcon, InfoIcon, Trophy, MessageSquare, Users } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "components/ui/tooltip";
import type { PostInteraction } from "../../types";
import { TooltipCell, TooltipHeader } from "components/tooltip/table-tooltips";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";

interface ColumnConfig {
  syncingProfiles: Set<string>;
  onGenerateIcebreaker: (linkedinId: string) => Promise<void>;
}

export const createColumns = ({ syncingProfiles, onGenerateIcebreaker }: ColumnConfig): ColumnDef<PostInteraction>[] => [
  {
    id: "fullName",
    accessorFn: (row) => `${row.profile.firstName} ${row.profile.lastName}`,
    header: "Full Name",
    cell: ({ row }) => {
      const fullName = `${row.original.profile.firstName} ${row.original.profile.lastName}`;
      const initials = `${row.original.profile.firstName?.[0] || ''}${row.original.profile.lastName?.[0] || ''}`.toUpperCase();
      
      return (
        <div className="flex items-center gap-2">
          <Avatar className="h-8 w-8">
            <AvatarImage 
              src={row.original.profile.profileImageUrl || undefined} 
              alt={fullName} 
            />
            <AvatarFallback>{initials}</AvatarFallback>
          </Avatar>
          <span className="font-medium">{fullName}</span>
        </div>
      );
    },
  },
  {
    id: "title",
    accessorFn: (row) => row.profile.title,
    header: "Title",
    cell: ({ row }) => (
      <div className="flex items-center gap-2">
        <span>{row.original.profile.title}</span>
      </div>
    ),
  },
  {
    id: "location",
    accessorFn: (row) => row.profile.locationName,
    header: "Location",
    cell: ({ row }) => (
      <div className="flex items-center gap-2">
        <MapPin className="h-4 w-4 text-muted-foreground" />
        <span>{row.original.profile.locationName}</span>
      </div>
    ),
  },
  {
    id: "company",
    accessorFn: (row) => row.profile.company,
    header: "Company",
    cell: ({ row }) => (
      <div className="flex items-center gap-2">
        <Building2 className="h-4 w-4 text-muted-foreground" />
        <span>{row.original.profile.company}</span>
      </div>
    ),
  },
  {
    id: "engagementCount",
    accessorFn: (row) => row.interactions?.count,
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant={isSorted ? "secondary" : "ghost"}
              onClick={() => column.toggleSorting(isSorted === "asc")}
              className="hover:bg-muted flex items-center gap-1 mx-auto"
            >
              Engagement Count
              <HelpCircle className="h-4 w-4 text-muted-foreground" />
              <ArrowUpDown
                className={`ml-2 h-4 w-4 ${isSorted ? "text-primary" : "text-muted-foreground"}`}
              />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p className="max-w-xs">
              Total number of interactions this lead has had with your LinkedIn content
            </p>
          </TooltipContent>
        </Tooltip>
      );
    },
    cell: ({ row }) => {
      const count = row.original.interactions?.count;
      return count ? (
        <TooltipCell
          value={count}
          description="Total number of interactions this lead has had with your LinkedIn content"
        />
      ) : (
        <span className="text-muted-foreground">0</span>
      );
    },
  },
  {
    id: "icpScore",
    accessorFn: (row) => row.profile.lead?.icpScore,
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant={isSorted ? "secondary" : "ghost"}
              onClick={() => column.toggleSorting(isSorted === "asc")}
              className="hover:bg-muted flex items-center gap-1 mx-auto"
            >
              Lead Score
              <HelpCircle className="h-4 w-4 text-muted-foreground" />
              <ArrowUpDown
                className={`ml-2 h-4 w-4 ${isSorted ? "text-primary" : "text-muted-foreground"}`}
              />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p className="max-w-xs">
              Match score with your Ideal Customer Profile
            </p>
          </TooltipContent>
        </Tooltip>
      );
    },
    cell: ({ row }) => {
      const score = row.original.profile.lead?.icpScore;
      return (
        <TooltipCell
          value={score || 0}
          description="Match score with your Ideal Customer Profile"
        />
      );
    },
  },
  {
    id: "leadInsights",
    header: () => (
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            className="hover:bg-muted flex items-center gap-1 mx-auto"
          >
            Lead Insights
            <HelpCircle className="h-4 w-4 text-muted-foreground" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p className="max-w-xs">
            View AI analysis of engagement patterns and ICP alignment
          </p>
        </TooltipContent>
      </Tooltip>
    ),
    cell: ({ row }) => {
      const lead = row.original.profile.lead;
      if (!lead?.engagementFactors && !lead?.icpAlignment && !lead?.qualificationReason) {
        return null;
      }

      return (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button 
                variant="ghost" 
                size="sm"
                className="flex items-center gap-2 hover:bg-blue-50 hover:text-blue-500 transition-colors"
              >
                <InfoIcon className="h-4 w-4" />
                View Insights
              </Button>
            </TooltipTrigger>
            <TooltipContent side="right" align="start" className="w-80 bg-white text-foreground border shadow-sm">
              <div className="space-y-4">
                <div className="flex items-center gap-2 pb-2 border-b">
                  <Trophy className="h-5 w-5 text-yellow-500" />
                  <h3 className="font-semibold text-foreground">Why this Lead Score?</h3>
                </div>
                {lead.engagementFactors && (
                  <div className="space-y-1">
                    <h4 className="text-sm font-semibold flex items-center gap-2 text-foreground">
                      <MessageSquare className="h-4 w-4 text-blue-500" />
                      Engagement Factors
                    </h4>
                    <p className="text-sm text-muted-foreground">{lead.engagementFactors}</p>
                  </div>
                )}
                {lead.icpAlignment && (
                  <div className="space-y-1">
                    <h4 className="text-sm font-semibold flex items-center gap-2 text-foreground">
                      <Users className="h-4 w-4 text-green-500" />
                      ICP Alignment
                    </h4>
                    <p className="text-sm text-muted-foreground">{lead.icpAlignment}</p>
                  </div>
                )}
                {lead.qualificationReason && (
                  <div className="space-y-1">
                    <h4 className="text-sm font-semibold flex items-center gap-2 text-foreground">
                      <Trophy className="h-4 w-4 text-yellow-500" />
                      Qualification
                    </h4>
                    <p className="text-sm text-muted-foreground">{lead.qualificationReason}</p>
                  </div>
                )}
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
    }
  },
  {
    id: "linkedin",
    header: () => {
      return <div className="text-center">LinkedIn</div>;
    },
    cell: ({ row }) => {
      const linkedinUrl = `https://www.linkedin.com/in/${row.original.profile.linkedinId}`;
      return (
        <Button
          variant="outline"
          size="sm"
          onClick={(e) => {
            e.stopPropagation();
            window.open(linkedinUrl, '_blank');
          }}
          className="flex items-center gap-2 text-[#0A66C2] hover:text-[#0A66C2]/90 hover:bg-[#0A66C2]/10"
        >
          <LinkedinIcon className="h-4 w-4" />
          View Profile
        </Button>
      );
    }
  },
  {
    id: "icebreaker",
    accessorFn: (row) => row.profile.lead?.icebreaker,
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <TooltipHeader
          title="Icebreaker"
          description="AI-generated conversation starter based on profile"
        />
      );
    },
    cell: ({ row }) => {
      const icebreaker = row.original.profile.lead?.icebreaker;
      const isSyncing = syncingProfiles.has(row.original.profile.linkedinId);

      return (
        <div className="max-w-md">
          {icebreaker ? (
            <TooltipCell
              value={
                <p className="text-sm line-clamp-2 hover:text-primary transition-colors">
                  {icebreaker}
                </p>
              }
              description={icebreaker}
            />
          ) : (
            <Button
              variant="outline"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                onGenerateIcebreaker(row.original.profile.linkedinId);
              }}
              disabled={isSyncing}
              className="w-full"
            >
              {isSyncing ? (
                <>
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  Generating...
                </>
              ) : (
                <>Generate Icebreaker</>
              )}
            </Button>
          )}
        </div>
      );
    }
  }
]; 