import { useAnalytics } from "lib/analytics";
import type {
  LinkedinProfileEvent,
  LinkedinPublicProfileEvent,
  LinkedinLeadEvent,
  LinkedinLeadSearchEvent,
  LinkedinPostEvent,
  LinkedinLeadViewEvent,
  UserSettingsEvent,
  LinkedinLeadTableEvent,
  LinkedinLeadPageEvent,
} from "./types/events";

enum EventPrefix {
  LEAD_LENS = 'lead_lens.'
}

enum EventNames {
  LINKEDIN_PROFILE_PAGE_VIEW = 'linkedin_profile.page_view',
  LINKEDIN_PUBLIC_PROFILE_VIEW = 'linkedin_public_profile.view',
  LINKEDIN_LEAD_GENERATE_ICEBREAKER = 'linkedin_lead.generate_icebreaker',
  LINKEDIN_LEAD_SEARCH = 'linkedin_lead.search',
  LINKEDIN_POST_SYNC_INITIATED = 'linkedin_post.sync_initiated',
  LINKEDIN_LEAD_VIEW_TOGGLE = 'linkedin_lead.view_toggle',
  USER_SETTINGS_ICP_UPDATE = 'user_settings.icp_update',
  LINKEDIN_LEAD_TABLE_SORT = 'linkedin_lead.table_sort',
  LINKEDIN_LEAD_PAGE_SIZE_CHANGE = 'linkedin_lead.page_size_change'
}

export const useLeadLensEvents = () => {
  const { captureEvent } = useAnalytics();
  const EVENT_PREFIX = 'lead_lens.';

  const captureLeadLensEvent = (eventName: EventNames, properties: any) => {
    captureEvent(`${EventPrefix.LEAD_LENS}${eventName}`, properties);
  };

  return {
    trackPageView: (properties: LinkedinProfileEvent) => {
      captureLeadLensEvent(EventNames.LINKEDIN_PROFILE_PAGE_VIEW, properties);
    },

    trackProfileView: (properties: LinkedinPublicProfileEvent) => {
      captureLeadLensEvent(EventNames.LINKEDIN_PUBLIC_PROFILE_VIEW, properties);
    },

    trackIcebreakerGeneration: (properties: LinkedinLeadEvent) => {
      captureLeadLensEvent(EventNames.LINKEDIN_LEAD_GENERATE_ICEBREAKER, properties);
    },

    trackLeadSearch: (properties: LinkedinLeadSearchEvent) => {
      captureLeadLensEvent(EventNames.LINKEDIN_LEAD_SEARCH, properties);
    },

    trackPostSync: (properties: LinkedinPostEvent) => {
      captureLeadLensEvent(EventNames.LINKEDIN_POST_SYNC_INITIATED, properties);
    },

    trackViewToggle: (properties: LinkedinLeadViewEvent) => {
      captureLeadLensEvent(EventNames.LINKEDIN_LEAD_VIEW_TOGGLE, properties);
    },

    trackIcpUpdate: (properties: UserSettingsEvent) => {
      captureLeadLensEvent(EventNames.USER_SETTINGS_ICP_UPDATE, properties);
    },

    trackTableSort: (properties: LinkedinLeadTableEvent) => {
      captureLeadLensEvent(EventNames.LINKEDIN_LEAD_TABLE_SORT, properties);
    },

    trackPageSizeChange: (properties: LinkedinLeadPageEvent) => {
      captureLeadLensEvent(EventNames.LINKEDIN_LEAD_PAGE_SIZE_CHANGE, properties);
    },
  };
}; 