import AmplificationBlog from "./_components/tools/AmplificationBlog/page";
import BlogFromMultimedia from "./_components/tools/BlogFromMultimedia/page";
import BlogFromPostsBulk from "./_components/tools/BlogFromPostsBulk/page";
import { CompanyResearch } from "./_components/tools/CompanyResearch/page";
import CreateChatbotDemo from "./_components/tools/CreateChatbotDemo/page";
import CustomVoiceManagement from "./_components/tools/CustomVoiceManagement/page";
import ProfilePersonaCreation from "./_components/tools/ProfilePersonaCreation/page";
import PublishToLinkedin from "./_components/tools/PublishToLinkedin/page";
import { WordpressBlogFromLinkedinPost } from "./_components/tools/WordpressBlogFromLinkedinPost/page";
import type { Tool } from "./types";

export const tools: Tool[] = [
	{
		name: "Blog from LinkedIn Post",
		shortDescription: "Select a linkedin post and generate a blog post",
		longDescription:
			"Publish a blog post in a matter of seconds by selecting a linkedin post.",
		href: "/dashboard/content-creation/blog-from-linkedin-post",
		component: WordpressBlogFromLinkedinPost,
		released: false,
		internal: false,
	},
	{
		name: "Blog from Multimedia",
		shortDescription: "Select a multimedia and generate a blog post",
		longDescription:
			"Publish a blog post in a matter of seconds by selecting a multimedia.",
		href: "/dashboard/content-creation/blog-from-multimedia",
		component: BlogFromMultimedia,
		released: true,
		internal: true,
	},
	{
		name: "Your posts",
		shortDescription: "Select a any number of posts and generate a blog post",
		longDescription:
			"Publish a blog post in a matter of seconds by selecting a any number of posts.",
		href: "/dashboard/content-creation/blog-from-posts-bulk",
		component: BlogFromPostsBulk,
		released: true,
		internal: true,
	},
	{
		name: "Amplification Blog",
		shortDescription: "Select own multimedia, and generate a blog post",
		longDescription:
			"Publish a blog post about own stories, experiences, opinions in a matter of seconds.",
		href: "/dashboard/content-creation/amplification-blog",
		component: AmplificationBlog,
		released: true,
		internal: false,
	},
	{
		name: "Publish to Linkedin",
		shortDescription: "Publish a post to Linkedin from Inbound Tools",
		longDescription:
			"Select an organization and publish a post to LinkedIn using their tokens.",
		href: "/dashboard/content-creation/publish-to-linkedin",
		component: PublishToLinkedin,
		released: true,
		internal: true,
	},
	{
		name: "Create LinkedIn Profile Persona",
		shortDescription: "Create a profile persona for a user",
		longDescription:
			"Create a profile persona based on user information, LinkedIn PDF, and target audience. Give access to the chatbot via this tool.",
		href: "/dashboard/profile-persona/creation",
		component: ProfilePersonaCreation,
		released: true,
		internal: true,
	},
	{
		name: "Create chatbot demo (internal)",
		shortDescription: "Crear una demo de chatbot",
		longDescription:
			"Crea una demo de chatbot con nombre, metadatos y publicaciones de LinkedIn de muestra.",
		href: "/dashboard/content-creation/create-chatbot-demo",
		component: CreateChatbotDemo,
		released: true,
		internal: true,
	},
	{
		name: "Custom Voice Management (internal)",
		shortDescription: "Manage all custom voices",
		longDescription: "View and edit custom voices for all users",
		href: "/dashboard/content-creation/custom-voice-management",
		component: CustomVoiceManagement,
		released: true,
		internal: true,
	},
	{
		name: "Company Research (internal)",
		shortDescription: "Research companies and their C-level executives",
		longDescription:
			"Analyze and gather information about target companies and their C-level executives from LinkedIn profiles for business development purposes.",
		href: "/dashboard/content-creation/company-research",
		component: CompanyResearch,
		released: true,
		internal: true,
	},
];

export const domainTools = [
	{
		domain: "Squads Ventures",
		id: "3830378c-54a8-49b7-a161-e0c7b0b9a6f7",
		internal: true,
	},
	{
		domain: "Emblue",
		id: "be332384-de90-4ae9-a588-1d5ed8f2cfee",
		internal: true,
	},
	{
		domain: "Mental Test Lab",
		id: "fe382e7f-3ec4-4f17-9418-b4cf09b5335d",
		internal: true,
	},
	{
		domain: "Boris",
		id: "98c8b7aa-1d0e-4ba0-a498-ecf821491dd3",
		internal: true,
	},
	{
		domain: "Worth It VC",
		id: "b3c904be-645d-46ef-ab75-20223bf7fe21",
		internal: false,
	},
];
