"use client";

import {
	keepPreviousData,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import { useToast } from "components/hooks/use-toast";
import { AuthContext } from "components/lib";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
} from "components/ui/card";
import { Checkbox } from "components/ui/checkbox";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { Skeleton } from "components/ui/skeleton";
import { Switch } from "components/ui/switch";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/ui/table";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "components/ui/tooltip";
import { useAnalytics } from "lib/analytics";
import { ChevronDown, ChevronRight, Edit } from "lucide-react";
import { LayoutGrid, Table as TableIcon } from "lucide-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { appConfig } from "views/content-creation/config";
import { LinkedinRefreshButton } from "../../../_components/linkedin-refresh-button";
import type {
	GetMorePostsFetchType,
	LinkedPostMapping,
	LinkedinPostType,
	TransformToBlogResponse,
	WordpressDomain,
} from "../../../types";
import { PostFilter } from "../../../types";
import {
	WordPressCredentials,
	type WordpressCredentialsState,
} from "../WordpressBlogFromLinkedinPost/wordpress-credentials";
import type { WordPressUser } from "../WordpressBlogFromLinkedinPost/wordpress-users";
import { DomainSelectionModal } from "./components/DomainSelectionModal";
import { PostCards } from "./components/PostCards";
import { PostTable } from "./components/PostTable";
import { StatsCards } from "./components/StatsCards";

export default function BlogFromPostsBulk({
	t,
}: { t: (key: string) => string }) {
	const { captureEvent } = useAnalytics();
	const [selectedPosts, setSelectedPosts] = useState<string[]>([]);
	const [page, setPage] = useState(0);
	const [totalPosts, setTotalPosts] = useState<LinkedinPostType[]>([]);
	const [count, setCount] = useState(10);
	// biome-ignore lint/suspicious/noExplicitAny: <auth hook>
	const auth: any = useContext(AuthContext);
	const organizationId = auth?.user?.default_organization;
	const userId = auth?.user?.id;
	const queryClient = useQueryClient();
	const { toast } = useToast();
	const [expandedPosts, setExpandedPosts] = useState<string[]>([]);
	const [linkedPosts, setLinkedPosts] = useState<LinkedPostMapping[]>([]);
	const navigate = useNavigate();
	const [selectedDraftPost, setSelectedDraftPost] = useState<string | null>(
		null,
	);
	const [selectedPublishPost, setSelectedPublishPost] = useState<string | null>(
		null,
	);
	const [currentFilter, setCurrentFilter] = useState<PostFilter>(
		PostFilter.ALL,
	);
	const [selectedMonth, setSelectedMonth] = useState<string>("all");
	const [selectedYear, setSelectedYear] = useState<string>("all");
	const [wordpressConfig, setWordpressConfig] =
		useState<WordpressCredentialsState | null>(null);
	const [selectedUser, setSelectedUser] = useState<WordPressUser | undefined>(
		undefined,
	);
	const [selectedDomain, setSelectedDomain] = useState<
		WordpressDomain | undefined
	>(undefined);
	const [pageSize, setPageSize] = useState(10);
	const [pagination, setPagination] = useState({
		currentPage: 0,
		totalPages: 0,
	});
	const [viewMode, setViewMode] = useState<"table" | "cards">("table");
	const [isDomainModalOpen, setIsDomainModalOpen] = useState(false);
	const [selectedPostsForDomains, setSelectedPostsForDomains] = useState<
		LinkedinPostType[]
	>([]);
	const fetchLinkedinPosts = async ({
		userId,
		page,
		count,
	}: {
		userId: string;
		page: number;
		count: number;
	}) => {
		try {
			const { data } = await axios.get<GetMorePostsFetchType>(
				"/api/linkedin/posts",
				{
					params: {
						userId,
						count,
						page,
					},
				},
			);
			return data;
		} catch (error) {
			throw new Error("Error al cargar los posts de LinkedIn");
		}
	};

	useEffect(() => {
		if (selectedUser) {
			setPage(0);
			setPagination((prev) => ({ ...prev, currentPage: 0 }));
			setTotalPosts([]);
			queryClient.invalidateQueries({ queryKey: ["linkedinPosts"] });
		}
	}, [selectedUser, queryClient]);

	const { data, isError, isFetching, isPlaceholderData } = useQuery({
		queryKey: ["linkedinPosts", page, count, selectedUser?.userId],
		queryFn: async () => {
			if (selectedUser?.userId) {
				const response = await fetchLinkedinPosts({
					userId: selectedUser.userId,
					page,
					count,
				});

				return response;
			}
			return Promise.resolve({ posts: [], hasMore: false });
		},
		placeholderData: keepPreviousData,
		enabled: !!selectedUser,
	});

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (data?.posts) {
			setTotalPosts(data.posts);
		}
	}, [data?.posts, data?.hasMore, page]);

	const handlePostSelection = (postId: string) => {
		setSelectedPosts((prev) => {
			const newSelection = prev.includes(postId)
				? prev.filter((id) => id !== postId)
				: [...prev, postId];

			captureEvent("multiposting.post_selection_changed", {
				selected_posts_count: newSelection.length,
				user_id: userId,
				organization_id: organizationId,
				action: prev.includes(postId) ? "deselect" : "select",
			});

			return newSelection;
		});
	};

	const transformToBlogMutation = useMutation({
		mutationFn: async ({
			posts,
			userId,
			domainsToPublish,
		}: {
			posts: LinkedinPostType[];
			userId: string;
			domainsToPublish: number[];
		}) => {
			const { data } = await axios.post<TransformToBlogResponse>(
				"/api/content-creation/enqueue-blog-from-linkedin",
				{
					posts,
					userId: selectedUser?.userId || userId,
					domainsToPublish,
				},
			);
			return data;
		},
		onSuccess: (data) => {
			setSelectedPosts([]);
			toast({
				title: "¡Éxito!",
				description:
					"Tus blogs se estan creando, te notificaremos cuando esten listos",
			});
			queryClient.invalidateQueries({ queryKey: ["linkedinPosts"] });
		},
		onError: (error) => {
			console.error("Error al transformar posts:", error);
			toast({
				title: "Error",
				description: "No se pudieron transformar los posts a blog",
			});
		},
	});

	const handleTransformToBlog = () => {
		const selectedPostsData = totalPosts.filter((post) =>
			selectedPosts.includes(post.linkedin_id),
		);

		captureEvent("multiposting.bulk_transform_initiated", {
			posts_count: selectedPostsData.length,
			user_id: userId,
			organization_id: organizationId,
		});

		setSelectedPostsForDomains(selectedPostsData);
		setIsDomainModalOpen(true);
	};

	const handleMultiDomainPublish = async (selectedDomainIds: number[]) => {
		if (!selectedPostsForDomains.length) return;

		captureEvent("multiposting.bulk_publish_initiated", {
			posts_count: selectedPostsForDomains.length,
			domains_count: selectedDomainIds.length,
			user_id: userId,
			organization_id: organizationId,
			domain_ids: selectedDomainIds,
		});

		try {
			await Promise.all(
				selectedDomainIds.flatMap((domainId) =>
					selectedPostsForDomains.map((post) =>
						transformToBlogMutation.mutate({
							posts: [post],
							userId: selectedUser?.userId || userId,
							domainsToPublish: selectedDomainIds,
						}),
					),
				),
			);

			captureEvent("multiposting.bulk_publish_success", {
				posts_count: selectedPostsForDomains.length,
				domains_count: selectedDomainIds.length,
				user_id: userId,
				organization_id: organizationId,
			});

			toast({
				title: "¡Éxito!",
				description:
					"Tus blogs se estan creando, te notificaremos cuando esten listos",
			});

			setSelectedPosts([]);
		} catch (error) {
			captureEvent("multiposting.bulk_publish_error", {
				posts_count: selectedPostsForDomains.length,
				domains_count: selectedDomainIds.length,
				user_id: userId,
				organization_id: organizationId,
				error_message: error instanceof Error ? error.message : "Unknown error",
			});

			toast({
				title: "Error",
				description: "Error al publicar algunos posts",
			});
		}

		setIsDomainModalOpen(false);
		setSelectedPostsForDomains([]);
	};

	const handleEditDraft = (postId: string) => {
		console.log("Editando borrador del post:", postId);
	};

	const togglePostExpansion = (postId: string) => {
		setExpandedPosts((prev) =>
			prev.includes(postId)
				? prev.filter((id) => id !== postId)
				: [...prev, postId],
		);
	};

	const { data: linkedPostsData, isLoading: isLoadingLinkedPosts } = useQuery({
		queryKey: ["linkedPosts", userId],
		queryFn: async () => {
			const { data } = await axios.get<{ data: LinkedPostMapping[] }>(
				`/api/wordpress/get-linkedin-to-blog-posts/${userId}/${selectedDomain?.domain}`,
			);

			setLinkedPosts(data.data);
			return data;
		},
		enabled: !!selectedDomain,
	});

	const handleEditPublished = (
		blogPostId: string,
		domain?: string,
		status?: string,
	) => {
		const url = `/dashboard/content-creation/edit-blog/${blogPostId}`;
		const urlWithDomain = domain
			? `${url}?domain=${encodeURIComponent(domain)}`
			: url;
		const urlWithStatus = status
			? `${urlWithDomain}?status=${status}`
			: urlWithDomain;
		navigate(urlWithStatus);
	};

	const publishSinglePostMutation = useMutation({
		mutationFn: async ({
			post,
			isDraft,
		}: { post: LinkedinPostType; isDraft: boolean }) => {
			captureEvent("multiposting.single_post_transform_initiated", {
				post_id: post.linkedin_id,
				is_draft: isDraft,
				user_id: userId,
				organization_id: organizationId,
				domain_id: selectedDomain?.id,
			});

			if (isDraft) {
				setSelectedDraftPost(post.linkedin_id);
			} else {
				setSelectedPublishPost(post.linkedin_id);
			}

			const { data } = await axios.post<TransformToBlogResponse>(
				"/api/content-creation/enqueue-blog-from-linkedin",
				{
					posts: [post],
					userId: selectedUser?.userId || userId,
					domainsToPublish: [selectedDomain?.id],
				},
			);
			return data;
		},
		onSuccess: (data) => {
			setSelectedDraftPost(null);
			setSelectedPublishPost(null);
			captureEvent("multiposting.single_post_transform_success", {
				user_id: userId,
				organization_id: organizationId,
				domain_id: selectedDomain?.id,
			});
			toast({
				title: "¡Éxito!",
				description: "Post transformado exitosamente",
			});
			queryClient.invalidateQueries({ queryKey: ["linkedinPosts"] });
			queryClient.invalidateQueries({ queryKey: ["linkedPosts"] });
		},
		onError: (error) => {
			setSelectedDraftPost(null);
			setSelectedPublishPost(null);
			captureEvent("multiposting.single_post_transform_error", {
				user_id: userId,
				organization_id: organizationId,
				domain_id: selectedDomain?.id,
				error_message: error instanceof Error ? error.message : "Unknown error",
			});
			console.error("Error al transformar post:", error);
			toast({
				title: "Error",
				description: "No se pudo transformar el post",
			});
		},
	});

	const getAvailableYears = () => {
		const years = new Set(
			totalPosts.map((post) =>
				new Date(post.creation_time).getFullYear().toString(),
			),
		);
		return Array.from(years).sort((a, b) => b.localeCompare(a));
	};

	const getPostStatusBadgeStyle = (status?: string) => {
		const styles = {
			draft: "bg-orange-100 text-orange-700",
			pending: "bg-yellow-100 text-yellow-700",
			publish: "bg-green-100 text-green-700",
			failed: "bg-red-100 text-red-700",
		};

		return styles[status as keyof typeof styles] || "";
	};

	const getPostStatusLabel = (t: (key: string) => string, status?: string) => {
		const labels = {
			draft: t("content-creation.blog_from_posts_bulk.draft_status"),
			pending: t("content-creation.blog_from_posts_bulk.pending_status"),
			publish: t("content-creation.blog_from_posts_bulk.published_status"),
			failed: t("content-creation.blog_from_posts_bulk.failed_status"),
		};

		return labels[status as keyof typeof labels] || status || "";
	};

	type FilterFunction = (post: LinkedinPostType) => boolean;

	const createFilterMap = (
		linkedPosts: LinkedPostMapping[] | undefined,
	): Record<PostFilter, FilterFunction> => ({
		[PostFilter.PUBLISHED]: (post: LinkedinPostType) =>
			!!linkedPosts?.find(
				(item) =>
					item.linkedin_post_id === post.linkedin_id &&
					item.status === "publish",
			),
		[PostFilter.DRAFT]: (post: LinkedinPostType) =>
			!!linkedPosts?.find(
				(item) =>
					item.linkedin_post_id === post.linkedin_id && item.status === "draft",
			),
		[PostFilter.PENDING]: (post: LinkedinPostType) =>
			!!linkedPosts?.find(
				(item) =>
					item.linkedin_post_id === post.linkedin_id &&
					item.status === "pending",
			),
		[PostFilter.FAILED]: (post: LinkedinPostType) =>
			!!linkedPosts?.find(
				(item) =>
					item.linkedin_post_id === post.linkedin_id &&
					item.status === "failed",
			),
		[PostFilter.UNPUBLISHED]: (post: LinkedinPostType) =>
			!linkedPosts?.find((item) => item.linkedin_post_id === post.linkedin_id),
		[PostFilter.ALL]: () => true,
	});

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const getFilteredPosts = useCallback(
		(posts: LinkedinPostType[], skipPagination = false) => {
			let filtered = posts;

			if (selectedMonth !== "all" || selectedYear !== "all") {
				filtered = filtered.filter((post) => {
					const postDate = new Date(post.creation_time);
					const matchesYear =
						selectedYear === "all" ||
						postDate.getFullYear().toString() === selectedYear;
					const matchesMonth =
						selectedMonth === "all" ||
						postDate.getMonth().toString() === selectedMonth;
					return matchesYear && matchesMonth;
				});
			}

			const filterMap = createFilterMap(linkedPosts);
			const filterFunction = filterMap[currentFilter];
			const filteredPosts = filtered.filter(filterFunction);

			if (skipPagination) {
				return filteredPosts;
			}

			const start = pagination.currentPage * pageSize;
			const end = start + pageSize;
			return filteredPosts.slice(start, end);
		},
		[
			currentFilter,
			linkedPosts,
			pagination.currentPage,
			pageSize,
			selectedMonth,
			selectedYear,
		],
	);

	useEffect(() => {
		const allFilteredPosts = getFilteredPosts(totalPosts, true);
		setPagination((prev) => ({
			...prev,
			totalPages: Math.ceil(allFilteredPosts.length / pageSize),
		}));
	}, [totalPosts, pageSize, getFilteredPosts]);

	useEffect(() => {
		if (wordpressConfig) {
			if (
				wordpressConfig.domainId &&
				wordpressConfig.webUrl &&
				wordpressConfig.organizationId
			) {
				const domain: WordpressDomain = {
					id: wordpressConfig.domainId.toString(),
					domain: wordpressConfig.webUrl,
					organizationId: wordpressConfig.organizationId,
				};
				setSelectedDomain(domain);
			}

			if (wordpressConfig.user) {
				const wpUser = {
					id: wordpressConfig.user.id,
					username: wordpressConfig.user.username,
					name: wordpressConfig.user.name,
					userId: wordpressConfig.user.userId || null,
					email: wordpressConfig.user.email || null,
					domain_id: wordpressConfig.domainId || 0,
					wp_user_id: wordpressConfig.user.wp_user_id,
					fetched_at: wordpressConfig.user.fetched_at,
				};
				setSelectedUser(wpUser);
				console.log("Usuario de WordPress seleccionado:", wpUser);
			}
		}
	}, [wordpressConfig]);

	const handlePageChange = (newPage: number) => {
		captureEvent("multiposting.page_changed", {
			from_page: pagination.currentPage,
			to_page: newPage,
			user_id: userId,
			organization_id: organizationId,
		});

		setPage(newPage);
		setPagination((prev) => ({ ...prev, currentPage: newPage }));
	};

	const { data: linkedinPostCount } = useQuery({
		queryKey: ["linkedinPostCount", userId],
		queryFn: async () => {
			const { data } = await axios.get(
				`/api/linkedin/get/post-count?userId=${userId}`,
			);
			return data;
		},
	});

	const fetchWordPressUser = async (
		userId: string,
	): Promise<WordPressUser[]> => {
		const response = await fetch(
			`${appConfig.API_MAIN_URL}/api/wordpress/get-user/${userId}`,
		);
		const { data } = await response.json();

		return data;
	};

	const { data: defaultUser } = useQuery({
		queryKey: ["wordPressUser", userId],
		queryFn: () => fetchWordPressUser(userId),
	});

	const { data: selectedUserDomains } = useQuery({
		queryKey: ["wordPressUser", selectedUser?.userId],
		queryFn: () =>
			selectedUser?.userId
				? fetchWordPressUser(selectedUser.userId)
				: Promise.resolve([]),
		enabled: !!selectedUser?.userId,
	});

	return (
		<div className="container mx-auto p-4">
			<StatsCards
				posts={totalPosts}
				linkedPosts={linkedPosts}
				linkedinPostCount={linkedinPostCount || 0}
				t={t}
			/>
			<WordPressCredentials
				userId={userId || ""}
				organizationId={organizationId || ""}
				setWordpressConfig={setWordpressConfig}
				wordpressConfig={wordpressConfig}
			/>

			<div className="flex justify-between items-center mb-6 mt-4">
				<div className="flex items-center gap-4">
					<h1 className="text-2xl font-bold">
						{t("content-creation.blog_from_posts_bulk.title")}
					</h1>
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger asChild>
								<div>
									<LinkedinRefreshButton />
								</div>
							</TooltipTrigger>
							<TooltipContent>
								<p>
									{t(
										"content-creation.blog_from_posts_bulk.sync_posts_tooltip",
									)}
								</p>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>

					<>
						<div className="flex items-center gap-2">
							<Select value={selectedMonth} onValueChange={setSelectedMonth}>
								<SelectTrigger className="w-[140px]">
									<SelectValue placeholder="Mes" />
								</SelectTrigger>
								<SelectContent>
									<SelectItem value="all">
										{t("content-creation.blog_from_posts_bulk.all_months")}
									</SelectItem>
									<SelectItem value="0">Enero</SelectItem>
									<SelectItem value="1">Febrero</SelectItem>
									<SelectItem value="2">Marzo</SelectItem>
									<SelectItem value="3">Abril</SelectItem>
									<SelectItem value="4">Mayo</SelectItem>
									<SelectItem value="5">Junio</SelectItem>
									<SelectItem value="6">Julio</SelectItem>
									<SelectItem value="7">Agosto</SelectItem>
									<SelectItem value="8">Septiembre</SelectItem>
									<SelectItem value="9">Octubre</SelectItem>
									<SelectItem value="10">Noviembre</SelectItem>
									<SelectItem value="11">Diciembre</SelectItem>
								</SelectContent>
							</Select>

							<Select value={selectedYear} onValueChange={setSelectedYear}>
								<SelectTrigger className="w-[140px]">
									<SelectValue placeholder="Año" />
								</SelectTrigger>
								<SelectContent>
									<SelectItem value="all">
										{t("content-creation.blog_from_posts_bulk.all_status")}
									</SelectItem>
									{getAvailableYears().map((year) => (
										<SelectItem key={year} value={year}>
											{year}
										</SelectItem>
									))}
								</SelectContent>
							</Select>
						</div>
						<div className="flex gap-2">
							<Button
								variant={
									currentFilter === PostFilter.ALL ? "default" : "outline"
								}
								size="sm"
								onClick={() => setCurrentFilter(PostFilter.ALL)}
							>
								{t("content-creation.blog_from_posts_bulk.all_status")}
							</Button>
							<Button
								variant={
									currentFilter === PostFilter.PUBLISHED ? "default" : "outline"
								}
								size="sm"
								onClick={() => setCurrentFilter(PostFilter.PUBLISHED)}
							>
								{t("content-creation.blog_from_posts_bulk.published_status")}
							</Button>
							<Button
								variant={
									currentFilter === PostFilter.DRAFT ? "default" : "outline"
								}
								size="sm"
								onClick={() => setCurrentFilter(PostFilter.DRAFT)}
							>
								{t("content-creation.blog_from_posts_bulk.draft_status")}
							</Button>
							<Button
								variant={
									currentFilter === PostFilter.UNPUBLISHED
										? "default"
										: "outline"
								}
								size="sm"
								onClick={() => setCurrentFilter(PostFilter.UNPUBLISHED)}
							>
								{t("content-creation.blog_from_posts_bulk.unpublished_status")}
							</Button>
							<Button
								variant={
									currentFilter === PostFilter.FAILED ? "default" : "outline"
								}
								size="sm"
								onClick={() => setCurrentFilter(PostFilter.FAILED)}
							>
								{t("content-creation.blog_from_posts_bulk.failed_status")}
							</Button>
						</div>
					</>
				</div>
				<div className="flex items-center gap-4">
					<div className="flex items-center gap-2">
						<TableIcon
							className={`h-4 w-4 ${viewMode === "table" ? "text-primary" : "text-muted-foreground"}`}
						/>
						<Switch
							checked={viewMode === "cards"}
							onCheckedChange={(checked) =>
								setViewMode(checked ? "cards" : "table")
							}
						/>
						<LayoutGrid
							className={`h-4 w-4 ${viewMode === "cards" ? "text-primary" : "text-muted-foreground"}`}
						/>
					</div>
					<Button
						onClick={handleTransformToBlog}
						disabled={
							selectedPosts.length === 0 || transformToBlogMutation.isPending
						}
					>
						{transformToBlogMutation.isPending
							? t("content-creation.blog_from_posts_bulk.transforming")
							: `${t("content-creation.blog_from_posts_bulk.transform_to_blog")} (${selectedPosts.length})`}
					</Button>
				</div>
			</div>

			{isError && (
				<div className="text-center text-red-500 mb-4">
					{t("content-creation.blog_from_posts_bulk.error_fetching_posts")}
				</div>
			)}

			<div className="rounded-md border mt-4">
				{viewMode === "table" ? (
					<PostTable
						posts={getFilteredPosts(totalPosts, true)}
						selectedPosts={selectedPosts}
						onPostSelection={handlePostSelection}
						expandedPosts={expandedPosts}
						togglePostExpansion={togglePostExpansion}
						linkedPosts={linkedPosts}
						onEditPublished={handleEditPublished}
						publishSinglePost={(post, isDraft) =>
							publishSinglePostMutation.mutate({ post, isDraft })
						}
						selectedDomain={selectedDomain}
						selectedDraftPost={selectedDraftPost}
						selectedPublishPost={selectedPublishPost}
						isLoadingLinkedPosts={isLoadingLinkedPosts}
						t={t}
					/>
				) : (
					<PostCards
						posts={getFilteredPosts(totalPosts, true)}
						selectedPosts={selectedPosts}
						onPostSelection={handlePostSelection}
						expandedPosts={expandedPosts}
						togglePostExpansion={togglePostExpansion}
						linkedPosts={linkedPosts}
						onEditPublished={handleEditPublished}
						publishSinglePost={(post, isDraft) =>
							publishSinglePostMutation.mutate({ post, isDraft })
						}
						selectedDomain={selectedDomain}
						selectedDraftPost={selectedDraftPost}
						selectedPublishPost={selectedPublishPost}
						isLoadingLinkedPosts={isLoadingLinkedPosts}
						t={t}
					/>
				)}
			</div>

			<div className="flex items-center justify-between px-2 mt-8">
				<div className="flex items-center gap-2">
					<Select
						value={pageSize.toString()}
						onValueChange={(value) => {
							const newSize = Number(value);
							setPageSize(newSize);
							setCount(newSize);
							setPagination((prev) => ({ ...prev, currentPage: 0 }));
							setPage(0);
							setTotalPosts([]);
						}}
					>
						<SelectTrigger className="w-[180px]">
							<SelectValue
								placeholder={t(
									"content-creation.blog_from_posts_bulk.items_per_page",
								)}
							/>
						</SelectTrigger>
						<SelectContent>
							<SelectItem value="5">
								5 {t("content-creation.blog_from_posts_bulk.items_per_page")}
							</SelectItem>
							<SelectItem value="10">
								10 {t("content-creation.blog_from_posts_bulk.items_per_page")}
							</SelectItem>
							<SelectItem value="20">
								20 {t("content-creation.blog_from_posts_bulk.items_per_page")}
							</SelectItem>
							<SelectItem value="50">
								50 {t("content-creation.blog_from_posts_bulk.items_per_page")}
							</SelectItem>
							<SelectItem value="100">
								100 {t("content-creation.blog_from_posts_bulk.items_per_page")}
							</SelectItem>
						</SelectContent>
					</Select>
					<p className="text-sm text-muted-foreground">
						{t("content-creation.blog_from_posts_bulk.showing")}{" "}
						{pagination.currentPage * pageSize + 1} -{" "}
						{Math.min(
							(pagination.currentPage + 1) * pageSize,
							getFilteredPosts(totalPosts, true).length,
						)}{" "}
						{t("content-creation.blog_from_posts_bulk.of")}{" "}
						{getFilteredPosts(totalPosts, true).length}
					</p>
				</div>
				<div className="flex gap-2">
					<Button
						variant="outline"
						size="sm"
						onClick={() => handlePageChange(pagination.currentPage - 1)}
						disabled={pagination.currentPage === 0}
					>
						{t("content-creation.blog_from_posts_bulk.previous")}
					</Button>
					<div className="flex items-center gap-2">
						<span className="text-sm">
							{pagination.currentPage + 1} / {pagination.totalPages}
						</span>
					</div>
					<Button
						variant="outline"
						size="sm"
						onClick={() => handlePageChange(pagination.currentPage + 1)}
						disabled={!data?.hasMore || isFetching}
					>
						{t("content-creation.blog_from_posts_bulk.next")}
					</Button>
				</div>
			</div>

			<DomainSelectionModal
				isOpen={isDomainModalOpen}
				onClose={() => {
					setIsDomainModalOpen(false);
					setSelectedPostsForDomains([]);
				}}
				onConfirm={handleMultiDomainPublish}
				userDomains={selectedUserDomains || []}
			/>
		</div>
	);
}
