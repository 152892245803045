"use client";

import { BlockNoteView } from "@blocknote/mantine";
import { useCreateBlockNote } from "@blocknote/react";
import "@blocknote/mantine/style.css";
import "@blocknote/core/fonts/inter.css";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useToast } from "components/hooks/use-toast";
import { Button } from "components/ui/button";
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "components/ui/card";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Textarea } from "components/ui/textarea";
import { Minus, Plus } from "lucide-react";
import { useState } from "react";
import { type FieldErrors, useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";
import type { CompanyResearchResponse } from "./types";

const formSchema = z.object({
	companyName: z.string().min(1, "Company name is required"),
	companyUrl: z.string().url("Please enter a valid URL"),
	executiveUrls: z.array(
		z.object({
			url: z.string().url("Please enter a valid LinkedIn URL"),
		}),
	),
});

type FormData = z.infer<typeof formSchema>;

export const CompanyResearch = () => {
	const [result, setResult] = useState<string | null>(null);

	const { toast } = useToast();

	const editor = useCreateBlockNote({});

	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			companyName: "",
			companyUrl: "",
			executiveUrls: [{ url: "" }],
		},
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: "executiveUrls",
	});

	const { mutate, isPending } = useMutation({
		mutationFn: async (data: FormData) => {
			const transformedData = {
				...data,
				executiveUrls: data.executiveUrls.map((exec) => exec.url),
			};

			const response = await axios.post<CompanyResearchResponse>(
				"/api/company-research/generate",
				transformedData,
			);
			return response.data;
		},
		onSuccess: async (response) => {
			setResult(response.data);
			const blocks = await editor.tryParseMarkdownToBlocks(response.data);
			editor.replaceBlocks(editor.document, blocks);
		},
		onError: (error) => {
			console.error("Error generating profile:", error);
			toast({
				title: "Error",
				description:
					"Hubo un error al generar el perfil de la empresa. Por favor intenta de nuevo en unos minutos, o contacta al equipo de soporte si el problema persiste.",
				variant: "destructive",
			});
		},
	});

	const onSubmit = (data: FormData) => {
		console.log("Form submitted with data:", data);
		mutate(data);
	};

	const onError = (errors: FieldErrors<FormData>) => {
		console.error("Form validation errors:", errors);
	};

	return (
		<div className="container mx-auto p-4">
			<h1 className="text-2xl font-bold mb-4">Company Profile Generator</h1>
			<form onSubmit={handleSubmit(onSubmit, onError)} className="space-y-4">
				<div>
					<Label htmlFor="companyName">Company Name</Label>
					<Input
						id="companyName"
						{...register("companyName")}
						required
						className="bg-white"
					/>
					{errors.companyName && (
						<p className="text-red-500 text-sm mt-1">
							{errors.companyName.message}
						</p>
					)}
				</div>
				<div>
					<Label htmlFor="companyUrl">Company Website</Label>
					<Input
						id="companyUrl"
						{...register("companyUrl")}
						type="url"
						className="bg-white"
					/>
					{errors.companyUrl && (
						<p className="text-red-500 text-sm mt-1">
							{errors.companyUrl.message}
						</p>
					)}
				</div>

				<div>
					<Label>C-Level Executives</Label>
					{fields.map((field, index) => (
						<div key={field.id} className="flex items-center space-x-2 mt-2">
							<div className="flex-1">
								<Input
									{...register(`executiveUrls.${index}.url`)}
									placeholder="LinkedIn URL"
									className="bg-white"
								/>
								{errors.executiveUrls?.[index]?.url && (
									<p className="text-red-500 text-sm mt-1">
										{errors.executiveUrls[index]?.url?.message}
									</p>
								)}
							</div>
							{index > 0 && (
								<Button
									type="button"
									variant="destructive"
									size="icon"
									onClick={() => remove(index)}
									className="flex-shrink-0 w-8 h-8"
								>
									<Minus className="h-4 w-4" />
								</Button>
							)}
						</div>
					))}
					<Button
						type="button"
						variant="outline"
						size="icon"
						onClick={() => append({ url: "" })}
						className="w-8 h-8 mt-2 bg-white"
					>
						<Plus className="h-4 w-4" />
					</Button>
				</div>

				<Button type="submit" disabled={isPending} className="w-full">
					{isPending ? "Generating Profile..." : "Generate Profile"}
				</Button>
			</form>

			{result && (
				<Card className="mt-8">
					<CardHeader>
						<CardTitle>Generated Company Profile</CardTitle>
						<CardDescription>Based on the provided information</CardDescription>
					</CardHeader>
					<CardContent>
						<BlockNoteView editor={editor} />
					</CardContent>
				</Card>
			)}
		</div>
	);
};
