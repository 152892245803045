import { useState } from "react";
import { Button } from "components/ui/button";
import { Checkbox } from "components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import type { WordPressUser } from "../../WordpressBlogFromLinkedinPost/wordpress-users";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

interface Domain {
  id: number;
  name: string;
  url: string;
  domain: string;
}

interface DomainSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (selectedDomains: number[]) => void;
  userDomains: WordPressUser[];
}

export function DomainSelectionModal({ isOpen, onClose, onConfirm, userDomains }: DomainSelectionModalProps) {
  const [selectedDomains, setSelectedDomains] = useState<number[]>([]);
  const userDomainsArray = Array.isArray(userDomains) ? userDomains : [];

  const handleConfirm = () => {
    onConfirm(selectedDomains);
    onClose();
  };

  const fetchDomainName = async () => {
    const response = await axios.get("/api/wordpress/domains");

    return response.data.data;
  };

  const {data: domainsData} = useQuery({
    queryKey: ["domain"],
    queryFn: () => fetchDomainName(),

  });

  const getDomainName = (domainId: number) => {
    const domain = domainsData?.find((domain: Domain) => {
      return domain.id === domainId;
    });
    return domain?.domain;
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Seleccionar Dominios</DialogTitle>
          <DialogDescription>
            Elige los dominios donde quieres publicar tu blog.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          {userDomainsArray?.map((userDomain: WordPressUser) => (
            <div key={userDomain.domain_id} className="flex items-center space-x-2">
              <Checkbox
                id={userDomain.domain_id.toString()}
                checked={selectedDomains.includes(userDomain.domain_id)}
                onCheckedChange={(checked) => {
                  setSelectedDomains(
                    checked
                      ? [...selectedDomains, userDomain.domain_id]
                      : selectedDomains.filter((id) => id !== userDomain.domain_id)
                  );
                }}
              />
              <label
                htmlFor={userDomain.domain_id.toString()}
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {userDomain.name} ({
                 getDomainName(userDomain.domain_id)
                })
              </label>
            </div>
          ))}
        </div>
        <DialogFooter>
          <Button type="button" variant="secondary" onClick={onClose}>
            Cancelar
          </Button>
          <Button type="button" onClick={handleConfirm}>
            Publicar
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
} 