import type { ColumnDef, OnChangeFn, SortingState } from "@tanstack/react-table";
import {
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { Button } from "components/ui/button";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/ui/table";
import { format } from "date-fns";
import { ArrowUpDown, Eye, Loader2, RefreshCcw } from "lucide-react";
import { useState, useEffect } from "react";
import type { Post } from "../types";
import { TablePagination } from "components/table-pagination";

interface LeadsPerPostTableProps {
	posts: Post[];
	onPostClick: (post: Post) => void;
	onSyncLeads: (postId: string) => Promise<void>;
	currentPage: number;
	totalPages: number;
	onPageChange: (page: number) => void;
	pageSize: number;
	onPageSizeChange: (size: number) => void;
	totalItems: number;
	syncingPosts: Set<string>;
	sortBy: string;
	sortDirection: 'asc' | 'desc';
	onSortChange: (sortBy: string, sortDirection: 'asc' | 'desc') => void;
}

export const LeadsPerPostTable = ({
	posts,
	onPostClick,
	onSyncLeads,
	currentPage,
	totalPages,
	onPageChange,
	pageSize,
	onPageSizeChange,
	totalItems,
	syncingPosts,
	sortBy,
	sortDirection,
	onSortChange,
}: LeadsPerPostTableProps) => {
	const columns: ColumnDef<Post>[] = [
		{
			accessorKey: "content",
			header: ({ column }) => {
				const isSorted = column.getIsSorted();
				return (
					<Button
						variant={isSorted ? "secondary" : "ghost"}
						onClick={() => column.toggleSorting(isSorted === "asc")}
						className="hover:bg-muted text-start w-full justify-start"
					>
						Post Content
						<ArrowUpDown
							className={`ml-2 h-4 w-4 ${isSorted ? "text-primary" : "text-muted-foreground"}`}
						/>
					</Button>
				);
			},
			cell: ({ row }) => {
				const content = row.getValue("content") as string;
				return (
					<div className="relative group max-w-[300px]">
						<a
							href={row.original.url}
							target="_blank"
							rel="noopener noreferrer"
							onClick={(e) => e.stopPropagation()}
							className="text-start text-blue-500 hover:underline block truncate"
							title={content}
						>
							{content}
						</a>
						<div className="hidden group-hover:block absolute z-10 p-2 bg-white border rounded-md shadow-lg max-w-md mt-1">
							{content}
						</div>
					</div>
				);
			},
		},
		{
			accessorKey: "creation_time",
			header: ({ column }) => {
				const isSorted = column.getIsSorted();
				return (
					<Button
						variant={isSorted ? "secondary" : "ghost"}
						onClick={() => column.toggleSorting(isSorted === "asc")}
						className="hover:bg-muted"
					>
						Date
						<ArrowUpDown
								className={`ml-2 h-4 w-4 ${isSorted ? "text-primary" : "text-muted-foreground"}`}
						/>
					</Button>
				);
			},
			cell: ({ row }) =>
				format(new Date(row.getValue("creation_time")), "MMM d, yyyy HH:mm"),
		},
		{
			accessorKey: "metrics.likes",
			header: ({ column }) => {
				const isSorted = column.getIsSorted();
				return (
					<Button
						variant={isSorted ? "secondary" : "ghost"}
						onClick={() => column.toggleSorting(isSorted ? isSorted === "asc" : false)}
						className="hover:bg-muted"
					>
						Likes
						<ArrowUpDown
							className={`ml-2 h-4 w-4 ${isSorted ? "text-primary" : "text-muted-foreground"}`}
						/>
					</Button>
				);
			},
		},
		{
			accessorKey: "metrics.comments",
			header: ({ column }) => {
				const isSorted = column.getIsSorted();
				return (
					<Button
						variant={isSorted ? "secondary" : "ghost"}
						onClick={() => column.toggleSorting(isSorted ? isSorted === "asc" : false)}
						className="hover:bg-muted"
					>
						Comments
						<ArrowUpDown
							className={`ml-2 h-4 w-4 ${isSorted ? "text-primary" : "text-muted-foreground"}`}
						/>
					</Button>
				);
			},
		},
		{
			accessorKey: "metrics.shares",
			header: ({ column }) => {
				const isSorted = column.getIsSorted();
				return (
					<Button
						variant={isSorted ? "secondary" : "ghost"}
						onClick={() => column.toggleSorting(isSorted ? isSorted === "asc" : false)}
						className="hover:bg-muted"
					>
						Shares
						<ArrowUpDown
							className={`ml-2 h-4 w-4 ${isSorted ? "text-primary" : "text-muted-foreground"}`}
						/>
					</Button>
				);
			},
		},
		{
			accessorKey: "totalInteractions",
			header: ({ column }) => {
				const isSorted = column.getIsSorted();
				return (
					<Button
							variant={isSorted ? "secondary" : "ghost"}
							onClick={() => column.toggleSorting(isSorted === "asc")}
							className="hover:bg-muted"
						>
							Leads Count
							<ArrowUpDown
								className={`ml-2 h-4 w-4 ${isSorted ? "text-primary" : "text-muted-foreground"}`}
							/>
						</Button>
					);
			},
			cell: ({ row }) => {
				return (
					<div className="text-center font-medium">
						{row.original.totalInteractions || 0}
					</div>
				);
			},
		},
		{
			id: "actions",
			header: () => {
				return <div className="text-center">Actions</div>;
			},
			cell: ({ row }) => {
				const post = row.original;
				const isSyncing = syncingPosts.has(post.linkedin_id);
				return (
					<div className="flex items-center justify-center gap-2">
						{post.last_lead_sync ? (
							<>
								<Button
									variant="outline"
									size="sm"
									onClick={(e) => {
										e.stopPropagation();
										onPostClick(post);
									}}
								>
									<Eye className="mr-2 h-4 w-4" />
									View Leads
								</Button>
								<Button
									variant="outline"
									size="sm"
									disabled={isSyncing}
									onClick={(e) => handleSyncClick(e, post.linkedin_id)}
								>
									{isSyncing ? (
										<>
											<Loader2 className="mr-2 h-4 w-4 animate-spin" />
											Syncing...
										</>
									) : (
										<>
											<RefreshCcw className="mr-2 h-4 w-4" />
											Sync Again
										</>
									)}
								</Button>
							</>
						) : (
							<Button
								variant="outline"
								size="sm"
								disabled={isSyncing}
								onClick={(e) => handleSyncClick(e, post.linkedin_id)}
							>
								{isSyncing ? (
									<>
										<Loader2 className="mr-2 h-4 w-4 animate-spin" />
										Syncing...
									</>
								) : (
									<>
										<RefreshCcw className="mr-2 h-4 w-4" />
										Sync Leads
									</>
								)}
							</Button>
						)}
					</div>
				);
			},
		},
	];

	const [sorting, setSorting] = useState<SortingState>([
		{
			id: sortBy.includes('_') ? sortBy : sortBy,
			desc: sortDirection === 'desc',
		},
	]);

	useEffect(() => {
		setSorting([
			{
				id: sortBy.includes('.') ? sortBy : sortBy,
				desc: sortDirection === 'desc',
			},
		]);
	}, [sortBy, sortDirection]);

	const handleSortingChange: OnChangeFn<SortingState> = (updatedSorting) => {
		const newSorting = typeof updatedSorting === 'function' 
			? updatedSorting(sorting) 
			: updatedSorting;
		
		setSorting(newSorting);
		if (newSorting.length > 0) {
			const { id, desc } = newSorting[0];
			onSortChange(id, desc ? 'desc' : 'asc');
		}
	};

	const table = useReactTable({
		data: posts,
		columns,
		getCoreRowModel: getCoreRowModel(),
		onSortingChange: handleSortingChange,
		getSortedRowModel: getSortedRowModel(),
		state: {
			sorting,
		},
	});

	const handleSyncClick = async (e: React.MouseEvent, postId: string) => {
		e.stopPropagation();
		await onSyncLeads(postId);
	};

	return (
		<div className="space-y-4">
			<Table>
				<TableHeader>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => (
								<TableHead key={header.id} className="text-center">
									{header.isPlaceholder
										? null
										: flexRender(
												header.column.columnDef.header,
												header.getContext(),
											)}
								</TableHead>
							))}
						</TableRow>
					))}
				</TableHeader>
				<TableBody>
					{table.getRowModel().rows.map((row) => (
						<TableRow
							key={row.id}
							className="hover:bg-muted/50 cursor-pointer"
							onClick={() =>
								!syncingPosts.has(row.original.linkedin_id) &&
								onPostClick(row.original)
							}
						>
							{row.getVisibleCells().map((cell) => (
								<TableCell key={cell.id} className="text-center">
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>

			<TablePagination
				currentPage={currentPage}
				totalPages={totalPages}
				onPageChange={onPageChange}
				pageSize={pageSize}
				onPageSizeChange={onPageSizeChange}
				totalItems={totalItems}
			/>
		</div>
	);
}; 