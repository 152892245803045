import { useQueries, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Animate, AuthContext } from "components/lib";
import { Card } from "components/ui/card";
import { DatePickerWithRange } from "components/ui/date-range-picker";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { Separator } from "components/ui/separator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { endOfDay, format, subDays } from "date-fns";
import { useContext, useEffect, useMemo, useState } from "react";

import CollaboratorMetricsTableVsGoals from "./_components/collaborator-metrics-table-vs-goals";
import CollaboratorsMetricsCharts from "./_components/collaborators-metrics-charts";
import CollaboratorMetricsTable from "./_components/collaborators-metrics-table";
import ExtensionMessage from "./_components/extension-message";
import { GoalControls } from "./_components/goal-controls";
import { MetricsPieCharts } from "./_components/metrics-pie-charts";
import { MetricsPieChartsVsGoals } from "./_components/metrics-pie-charts-vs-goals";
import { PlatformSelector } from "./_components/platform-selector";
import { PostsTable } from "./_components/posts-table";
import { SkeletonSelect } from "./_components/skeleton-select";
import { SkeletonTable } from "./_components/skeleton-table";
import { SkeletonTabs } from "./_components/skeleton-tabs";
import { TeamSelect } from "./_components/team-select";
import { TopPostsCards } from "./_components/top-posts-cards";
import { sortedTableTimeFrameOptions } from "./utils";

const fetchTopPosts = async ({
	organizationId,
	platform,
	startDate,
	endDate,
}) => {
	const { data } = await axios.get(
		`/api/analytics/${organizationId}/${platform}/top-posts`,
		{
			params: {
				startDate,
				endDate,
				metric: "performance",
			},
		},
	);
	return data;
};

function filterDataByTeam(
	selectedTeamIds,
	weekProgressData,
	userInitialGoalMetrics,
) {
	if (!selectedTeamIds || selectedTeamIds.length === 0) {
		return {
			filteredWeekProgressData: weekProgressData,
			filteredUserInitialGoalMetrics: userInitialGoalMetrics,
		};
	}

	// Filter weekProgressData
	const filteredWeekProgressData = weekProgressData.map((weekData) => ({
		...weekData,
		impressions: weekData.impressions.filter((item) =>
			selectedTeamIds.includes(item.user_id),
		),
		likes: weekData.likes.filter((item) =>
			selectedTeamIds.includes(item.user_id),
		),
		comments: weekData.comments.filter((item) =>
			selectedTeamIds.includes(item.user_id),
		),
		shares: weekData.shares.filter((item) =>
			selectedTeamIds.includes(item.user_id),
		),
		posts: weekData.posts.filter((item) =>
			selectedTeamIds.includes(item.user_id),
		),
	}));

	// Filter userInitialGoalMetrics
	const filteredUserInitialGoalMetrics = userInitialGoalMetrics.map(
		(metric) => ({
			...metric,
			data: metric.data.filter((item) =>
				selectedTeamIds.includes(item.user_id),
			),
		}),
	);

	return { filteredWeekProgressData, filteredUserInitialGoalMetrics };
}

export function Dashboard(props) {
	const auth = useContext(AuthContext);

	const organizationId = auth?.user?.organization_id;

	const [timeFrame, setTimeFrame] = useState(sortedTableTimeFrameOptions[1]);
	const [dateRange, setDateRange] = useState({ from: null, to: null });
	const [showDatePicker, setShowDatePicker] = useState(false);
	const [customDateLabel, setCustomDateLabel] = useState("");

	const [vsGoal, setVsGoal] = useState(false);

	const [selectedGoal, setSelectedGoal] = useState(null);
	const [goalMetrics, setGoalMetrics] = useState([]);
	const [userInitialGoalMetrics, setUserInitialGoalMetrics] = useState([]);
	const [weekProgressData, setWeekProgressData] = useState([]);
	const [goalDataLoading, setGoalDataLoading] = useState(true);
	const [selectedWeek, setSelectedWeek] = useState(null);
	const [selectedPlatform, setSelectedPlatform] = useState("linkedin");
	const [selectedTeamId, setSelectedTeamId] = useState(null);

	const {
		data: teamsData,
		isLoading: teamsLoading,
		error: teamsError,
	} = useQuery({
		queryKey: ["teams", organizationId],
		queryFn: async () => {
			if (!organizationId) return null;
			const { data } = await axios.get(`/api/team/${organizationId}`);
			return data;
		},
		enabled: !!organizationId,
	});

	const teamUsersQueries = useQueries({
		queries: (teamsData || []).map((team) => ({
			queryKey: ["teamUsers", team.id],
			queryFn: async () => {
				const { data } = await axios.get(`/api/team/${team.id}/users`);
				return { teamId: team.id, users: data };
			},
			enabled: !!teamsData,
		})),
	});

	const teamUsers = useMemo(() => {
		const usersMap = {};
		if (teamUsersQueries.every((query) => query.isSuccess)) {
			for (const query of teamUsersQueries) {
				if (query.data) {
					usersMap[query.data.teamId] = query.data.users;
				}
			}
		}
		return usersMap;
	}, [teamUsersQueries]);

	const startDate = useMemo(() => {
		return timeFrame.value !== "custom"
			? format(subDays(new Date(), timeFrame.value), "yyyy-MM-dd'T'HH:mm:ssxxx")
			: format(dateRange.from, "yyyy-MM-dd'T'HH:mm:ssxxx");
	}, [timeFrame, dateRange.from]);

	const endDate = useMemo(() => {
		return timeFrame.value !== "custom"
			? format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx")
			: format(dateRange.to, "yyyy-MM-dd'T'HH:mm:ssxxx");
	}, [timeFrame, dateRange.to]);

	const {
		data: metrics,
		isLoading: metricsLoading,
		error,
	} = useQuery({
		queryKey: ["metrics", organizationId, startDate, endDate, timeFrame.type],
		queryFn: async () => {
			if (!organizationId) return null;
			const { data } = await axios.get(
				`/api/analytics/${organizationId}/collaborators`,
				{
					params: {
						startDate,
						endDate,
						type: timeFrame.type || "days",
					},
				},
			);
			return data;
		},
		enabled: !!organizationId,
	});

	if (error?.status === 401) {
		auth.signout();
	}

	const {
		data: usersSyncStatus,
		isLoading: usersSyncStatusLoading,
		error: usersSyncStatusError,
	} = useQuery({
		queryKey: ["usersSyncStatus", organizationId],
		queryFn: async () => {
			if (!organizationId) return null;
			const { data } = await axios.get(
				`/api/analytics/${organizationId}/users-sync-status`,
			);
			return data;
		},
		enabled: !!organizationId,
	});

	const {
		data: goals,
		isLoading: goalsLoading,
		error: goalsError,
	} = useQuery({
		queryKey: ["goals", organizationId],
		queryFn: async () => {
			if (!organizationId) return null;
			const { data } = await axios.get("/api/goal/get-all", {
				params: { organization_id: organizationId },
			});
			return data;
		},
		enabled: !!organizationId,
	});

	const {
		data: topPosts,
		isLoading: topPostsLoading,
		error: topPostsError,
	} = useQuery({
		queryKey: [
			"topPosts",
			organizationId,
			selectedPlatform,
			startDate,
			endDate,
		],
		queryFn: () =>
			fetchTopPosts({
				organizationId,
				platform: selectedPlatform,
				startDate:
					timeFrame.value !== "custom"
						? format(subDays(new Date(), timeFrame.value), "yyyy-MM-dd")
						: format(dateRange.from, "yyyy-MM-dd"),
				endDate:
					timeFrame.value !== "custom"
						? format(new Date(), "yyyy-MM-dd")
						: format(dateRange.to, "yyyy-MM-dd"),
			}),
		enabled: !!organizationId,
	});

	if (!organizationId) {
		return null;
	}

	const handleTimeFrameChange = (selectedTimeFrame) => {
		if (selectedTimeFrame.value === "custom") {
			setShowDatePicker(true);
		} else {
			setShowDatePicker(false);
			setTimeFrame(selectedTimeFrame);
			setCustomDateLabel("");
			setDateRange({ from: null, to: null });
		}
	};

	useEffect(() => {
		if (organizationId && !goalsLoading && !goalsError && goals) {
			setSelectedGoal(goals[0]);
		}
	}, [organizationId, goalsLoading, goalsError, goals]);

	useEffect(() => {
		if (selectedGoal) {
			setGoalDataLoading(true);
			axios
				.get(`/api/goal/get-goal-metrics?goal_id=${selectedGoal.id}`)
				.then((response) => {
					setGoalMetrics(response.data.data);
				})
				.catch((error) => {
					console.error("Error fetching metrics:", error);
				});
		}
	}, [selectedGoal]);

	useEffect(() => {
		if (goalMetrics?.length) {
			const initialGoalMetrics = [];
			const goalMetricIds = goalMetrics.map((metric) => metric.id).join(",");
			axios
				.get(
					`/api/goal/get-user-initial-goal-metrics?goal_metric_ids=${goalMetricIds}`,
				)
				.then((response) => {
					const goalMetricsData = response.data.data;

					for (const goalMetric of goalMetrics) {
						const filteredData = goalMetricsData.filter(
							(data) => data.goal_metric_id === goalMetric.id,
						);
						initialGoalMetrics.push({
							metricType: goalMetric.metric_type,
							weeklyGrowthRate: goalMetric.weekly_growth_rate,
							data: filteredData.map((data) => ({
								user_id: data.user_id,
								initial_value: data.initial_value,
								start_date: data.start_date,
							})),
						});
					}

					setUserInitialGoalMetrics(initialGoalMetrics);
				})
				.catch((error) => {
					console.error("Error fetching user initial goal metrics:", error);
				});
		}
	}, [goalMetrics]);

	useEffect(() => {
		if (
			selectedGoal &&
			goalMetrics &&
			goalMetrics.length &&
			userInitialGoalMetrics &&
			userInitialGoalMetrics.length
		) {
			const now = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
			const metricTypes = goalMetrics
				.map((metric) => metric.metric_type)
				.join(",");
			axios
				.get(
					`/api/goal/get-current-users-progress?goal_id=${selectedGoal.id}&start_date=${selectedGoal.start_date}&end_date=${now}&metrics=${metricTypes}`,
				)
				.then((response) => {
					const responseData = response.data.data;
					setWeekProgressData(responseData);
					setSelectedWeek(responseData[responseData.length - 1].week);
					setGoalDataLoading(false);
				})
				.catch((error) => {
					console.error("Error fetching metrics:", error);
					setGoalDataLoading(false);
				});
		}
	}, [selectedGoal, goalMetrics, userInitialGoalMetrics]);

	const filteredMetrics = useMemo(() => {
		if (!selectedTeamId || !teamUsers[selectedTeamId]) {
			return metrics;
		}
		const teamUserIds = teamUsers[selectedTeamId].map((user) => user.id);
		return metrics.filter((metric) => teamUserIds.includes(metric.user_id));
	}, [metrics, selectedTeamId, teamUsers]);

	const { filteredWeekProgressData, filteredUserInitialGoalMetrics } =
		filterDataByTeam(
			selectedTeamId ? teamUsers[selectedTeamId].map((user) => user.id) : [],
			weekProgressData,
			userInitialGoalMetrics,
		);

	useEffect(() => {
		if (vsGoal) {
			setSelectedPlatform("linkedin");
		}
	}, [vsGoal]);

	return (
		<Animate type="pop">
			<ExtensionMessage />
			<div className="flex flex-col gap-8 h-full">
				<div className="flex justify-end gap-4 items-center h-8">
					{metricsLoading ? (
						<SkeletonSelect />
					) : (
						<>
							{!goalsLoading &&
								goals?.length > 0 &&
								weekProgressData?.length > 0 &&
								selectedPlatform === "linkedin" && (
									<GoalControls
										vsGoal={vsGoal}
										setVsGoal={setVsGoal}
										selectedGoal={selectedGoal}
										setSelectedGoal={setSelectedGoal}
										selectedWeek={selectedWeek}
										setSelectedWeek={setSelectedWeek}
										goals={goals}
										weekProgressData={weekProgressData}
										t={props.t}
									/>
								)}

							{showDatePicker && (
								<DatePickerWithRange
									from={dateRange.from}
									to={dateRange.to}
									onDateChange={({ from, to }) => {
										const endOfTo = endOfDay(to);
										setDateRange({ from, to: endOfTo });
										const customLabel = `${format(from, "MMM d, yyyy")} - ${format(endOfTo, "MMM d, yyyy")}`;
										setCustomDateLabel(customLabel);
										setTimeFrame({
											label: customLabel,
											value: "custom",
											type: "custom",
										});
										setShowDatePicker(false);
									}}
									disabled={vsGoal}
								/>
							)}

							<Select
								value={customDateLabel ? "custom" : timeFrame.value}
								onValueChange={(value) =>
									handleTimeFrameChange(
										sortedTableTimeFrameOptions.find(
											(option) => option.value === value,
										),
									)
								}
								disabled={vsGoal}
							>
								<SelectTrigger className="max-w-60 bg-white">
									<SelectValue>
										{customDateLabel || props.t(timeFrame.label)}
									</SelectValue>
								</SelectTrigger>
								<SelectContent>
									<SelectGroup>
										<SelectLabel>Time Frames</SelectLabel>
										{sortedTableTimeFrameOptions.map((option) => (
											<SelectItem key={option.value} value={option.value}>
												{props.t(option.label)}
											</SelectItem>
										))}
									</SelectGroup>
								</SelectContent>
							</Select>

							{!teamsLoading && teamsData && !metricsLoading ? (
								<TeamSelect
									teams={teamsData}
									selectedTeamId={selectedTeamId}
									onSelectTeam={setSelectedTeamId}
									disabled={teamsLoading}
								/>
							) : (
								<SkeletonSelect />
							)}

							<Separator orientation="vertical" className="bg-black/25" />

							<PlatformSelector
								selectedPlatform={selectedPlatform}
								onPlatformChange={setSelectedPlatform}
								disabled={vsGoal}
							/>
						</>
					)}
				</div>

				<div className="flex justify-between items-center w-full">
					{vsGoal ? (
						<MetricsPieChartsVsGoals
							weekProgressData={filteredWeekProgressData}
							userInitialGoalMetrics={filteredUserInitialGoalMetrics}
							loading={goalDataLoading}
							selectedWeek={selectedWeek}
							goalMetrics={goalMetrics}
							goal={selectedGoal}
							selectedTeamId={selectedTeamId}
						/>
					) : (
						<MetricsPieCharts
							metrics={filteredMetrics}
							loading={metricsLoading}
							platform={selectedPlatform}
						/>
					)}
				</div>

				<Tabs defaultValue="table" className="w-full">
					{metricsLoading ? (
						<SkeletonTabs />
					) : (
						<>
							<TabsList className="grid w-full grid-cols-3">
								<TabsTrigger value="table">
									{props.t("dashboard.tabs.table")}
								</TabsTrigger>
								<TabsTrigger value="performance">
									{props.t("dashboard.tabs.performance")}
								</TabsTrigger>
								<TabsTrigger value="posts">
									{props.t("dashboard.tabs.posts")}
								</TabsTrigger>
							</TabsList>
						</>
					)}

					<TabsContent value="table" className="mt-6">
						<Card
							title={props.t("dashboard.collaborator_metrics.title")}
							className="min-h-[25rem] bg-white p-6 w-full"
						>
							{vsGoal ? (
								goalDataLoading ? (
									<SkeletonTable />
								) : (
									filteredWeekProgressData &&
									filteredUserInitialGoalMetrics && (
										<CollaboratorMetricsTableVsGoals
											weekProgressData={filteredWeekProgressData}
											userInitialGoalMetrics={filteredUserInitialGoalMetrics}
											selectedWeek={selectedWeek}
											goalMetrics={goalMetrics}
											goal={selectedGoal}
											selectedTeamId={selectedTeamId}
											usersSyncStatus={usersSyncStatus}
											t={props.t}
										/>
									)
								)
							) : metricsLoading && usersSyncStatusLoading ? (
								<SkeletonTable />
							) : (
								filteredMetrics &&
								usersSyncStatus && (
									<CollaboratorMetricsTable
										metrics={filteredMetrics}
										t={props.t}
										usersSyncStatus={usersSyncStatus}
										platform={selectedPlatform}
									/>
								)
							)}
						</Card>
					</TabsContent>

					<TabsContent value="performance" className="mt-6">
						<div className="min-h-[20rem] p-4">
							<div className="grid grid-cols-4 gap-6">
								<div className="col-span-3">
									<CollaboratorsMetricsCharts
										organizationId={organizationId}
										t={props.t}
										weeklyProgressData={weekProgressData}
										goalMetrics={goalMetrics}
										goal={selectedGoal}
										userInitialGoalMetrics={userInitialGoalMetrics}
										selectedTeamId={selectedTeamId}
										platform={selectedPlatform}
										vsGoal={vsGoal}
									/>
								</div>
								<div className="col-span-1">
									<TopPostsCards
										posts={topPosts}
										isLoading={topPostsLoading}
										error={topPostsError}
										platform={selectedPlatform}
										t={props.t}
									/>
								</div>
							</div>
						</div>
					</TabsContent>

					<TabsContent value="posts" className="mt-6">
						<Card
							title={props.t("dashboard.posts.title")}
							className="min-h-[25rem] bg-white p-6 w-full"
						>
							{topPostsLoading ? (
								<SkeletonTable />
							) : (
								<PostsTable
									posts={topPosts}
									platform={selectedPlatform}
									t={props.t}
								/>
							)}
						</Card>
					</TabsContent>
				</Tabs>
			</div>
		</Animate>
	);
}
