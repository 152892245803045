import { useState, useEffect } from "react";
import type { SortingState } from "@tanstack/react-table";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  OnChangeFn,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";

import { createColumns } from "./columns";
import { TooltipProvider } from "components/ui/tooltip";
import type { PostReactionInteraction } from "../../types";
import { TablePagination } from "components/table-pagination";

export interface LeadsTableProps {
  leads: PostReactionInteraction[];
  onLeadClick: (lead: PostReactionInteraction) => void;
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  pageSize: number;
  onPageSizeChange: (size: number) => void;
  totalItems: number;
  onGenerateIcebreaker: (linkedinId: string) => Promise<void>;
  syncingProfiles: Set<string>;
  sortBy: string;
  sortDirection: 'asc' | 'desc';
  onSortChange: (sortBy: string, sortDirection: 'asc' | 'desc') => void;
}

export const LeadsTable = ({
  leads,
  onLeadClick,
  currentPage,
  totalPages,
  onPageChange,
  pageSize,
  onPageSizeChange,
  totalItems,
  onGenerateIcebreaker,
  syncingProfiles,
  sortBy,
  sortDirection,
  onSortChange,
}: LeadsTableProps) => {
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: sortBy,
      desc: sortDirection === 'desc',
    },
  ]);

  useEffect(() => {
    setSorting([
      {
        id: sortBy,
        desc: sortDirection === 'desc',
      },
    ]);
  }, [sortBy, sortDirection]);

  const handleSortingChange: OnChangeFn<SortingState> = (updatedSorting) => {
    const newSorting = typeof updatedSorting === 'function' 
      ? updatedSorting(sorting) 
      : updatedSorting;
    
    setSorting(newSorting);
    if (newSorting.length > 0) {
      const { id, desc } = newSorting[0];
      onSortChange(id, desc ? 'desc' : 'asc');
    }
  };

  const columns = createColumns({ syncingProfiles, onGenerateIcebreaker });

  const table = useReactTable({
    data: leads,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: handleSortingChange,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  });

  return (
    <div className="space-y-4">
      <TooltipProvider delayDuration={0}>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                className="hover:bg-muted/50 cursor-pointer"
                onClick={() => onLeadClick({
                  ...row.original,
                  postInteraction: row.original.interactions.posts.map(post => ({
                    type: post.type as 'like' | 'comment' | 'share',
                    interactionDate: post.interactionDate,
                    comment: post.comment
                  }))
                })}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TooltipProvider>

      <TablePagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
        totalItems={totalItems}
      />
    </div>
  );
};