import { ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { Button } from "components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "components/ui/dialog";
import { Input } from "components/ui/input";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/ui/table";
import { differenceInWeeks, parseISO, setWeek, startOfWeek } from "date-fns";
import { useMemo, useState } from "react";
import SyncStatusBadge from "./sync-status-badge";

import {
	calculateCompletion,
	calculateExpectedValue,
	getCompletionColor,
} from "../utils";

const SortableHeaderButton = ({ column, label }) => {
	return (
		<Button
			className="px-0"
			variant="ghost"
			onClick={() => {
				column.toggleSorting();
			}}
		>
			{label}
			{column.getIsSorted() === "desc" ? (
				<ChevronDownIcon className="ml-2 w-4 h-4" />
			) : (
				column.getIsSorted() === "asc" && (
					<ChevronUpIcon className="ml-2 w-4 h-4" />
				)
			)}
		</Button>
	);
};

const TotalRow = ({
	metrics,
	previousWeekData,
	selectedTeamId,
	goal,
	goalMetrics,
	selectedWeek,
}) => {
	if (!metrics) return null;

	const calculateExpectedTotal = (metricType) => {
		if (selectedTeamId) {
			return metrics.reduce(
				(sum, metric) =>
					sum +
					(metric[metricType].expectedValue !== "-"
						? metric[metricType].expectedValue
						: 0),
				0,
			);
		}
		const metricKey = metricType === "interactions" ? "likes" : metricType;
		const relevantGoalMetric = goalMetrics.find(
			(gm) => gm.metric_type === metricKey,
		);
		if (!relevantGoalMetric) return 0;
		const startDate = parseISO(goal.start_date.split("T")[0]);
		const currentYear = new Date().getFullYear();
		const selectedWeekDate = setWeek(
			new Date(currentYear, 0, 1),
			selectedWeek,
			{ weekStartsOn: 1 },
		);
		const weeksPassed = differenceInWeeks(
			startOfWeek(selectedWeekDate, { weekStartsOn: 1 }),
			startOfWeek(startDate, { weekStartsOn: 1 }),
		);

		return Math.round(
			calculateExpectedValue(
				relevantGoalMetric.initial_value,
				relevantGoalMetric.weekly_growth_rate,
				weeksPassed,
			),
		);
	};

	const totals = {
		posts: metrics.reduce(
			(sum, metric) =>
				sum + (metric.posts.actualValue !== "-" ? metric.posts.actualValue : 0),
			0,
		),
		expectedPosts: calculateExpectedTotal("posts"),
		impressions: metrics.reduce(
			(sum, metric) =>
				sum +
				(metric.impressions.actualValue !== "-"
					? metric.impressions.actualValue
					: 0),
			0,
		),
		expectedImpressions: calculateExpectedTotal("impressions"),
		interactions: metrics.reduce(
			(sum, metric) =>
				sum +
				(metric.interactions.actualValue !== "-"
					? metric.interactions.actualValue
					: 0),
			0,
		),
		expectedInteractions: calculateExpectedTotal("interactions"),
		comments: metrics.reduce(
			(sum, metric) =>
				sum +
				(metric.comments.actualValue !== "-" ? metric.comments.actualValue : 0),
			0,
		),
		expectedComments: calculateExpectedTotal("comments"),
	};

	const previousTotals = previousWeekData
		? {
				posts: previousWeekData.posts.reduce(
					(sum, data) => sum + (data.value !== "-" ? data.value : 0),
					0,
				),
				impressions: previousWeekData.impressions.reduce(
					(sum, data) => sum + (data.value !== "-" ? data.value : 0),
					0,
				),
				interactions: previousWeekData.likes.reduce(
					(sum, data) => sum + (data.value !== "-" ? data.value : 0),
					0,
				),
				comments: previousWeekData.comments.reduce(
					(sum, data) => sum + (data.value !== "-" ? data.value : 0),
					0,
				),
			}
		: { posts: 0, impressions: 0, interactions: 0, comments: 0 };

	totals.postsWoW = calculateWoWChange(totals.posts, previousTotals.posts);
	totals.impressionsWoW = calculateWoWChange(
		totals.impressions,
		previousTotals.impressions,
	);
	totals.interactionsWoW = calculateWoWChange(
		totals.interactions,
		previousTotals.interactions,
	);
	totals.commentsWoW = calculateWoWChange(
		totals.comments,
		previousTotals.comments,
	);

	return (
		<TableRow>
			<TableCell className="font-bold text-base">Total</TableCell>
			<TableCell className="text-center border-l">
				<span className="text-base">{totals.posts}</span>
			</TableCell>
			<TableCell className="text-center border-l">
				<span className="text-base">{totals.expectedPosts}</span>
			</TableCell>
			<TableCell className="text-center border-l">
				<span
					className={`italic font-bold ${getCompletionColor(calculateCompletion(totals.posts, totals.expectedPosts))}`}
				>
					{Math.round(calculateCompletion(totals.posts, totals.expectedPosts))}%
				</span>
			</TableCell>
			<TableCell className="text-center border-l">
				<span
					className={`font-bold ${
						totals.postsWoW > 0
							? "text-green-500"
							: totals.postsWoW < 0
								? "text-red-500"
								: "text-black"
					}`}
				>
					<span className="text-base">
						{totals.postsWoW > 0 ? "+" : ""}
						{Math.round(totals.postsWoW)}%
					</span>
				</span>
			</TableCell>
			<TableCell className="text-center border-l">
				<span className="text-base">{totals.impressions}</span>
			</TableCell>
			<TableCell className="text-center border-l">
				<span className="text-base">{totals.expectedImpressions}</span>
			</TableCell>
			<TableCell className="text-center border-l">
				<span
					className={`italic font-bold ${getCompletionColor(calculateCompletion(totals.impressions, totals.expectedImpressions))}`}
				>
					{Math.round(
						calculateCompletion(totals.impressions, totals.expectedImpressions),
					)}
					%
				</span>
			</TableCell>
			<TableCell className="text-center border-l">
				<span
					className={`font-bold ${
						totals.impressionsWoW > 0
							? "text-green-500"
							: totals.impressionsWoW < 0
								? "text-red-500"
								: "text-black"
					}`}
				>
					<span className="text-base">
						{totals.impressionsWoW > 0 ? "+" : ""}
						{Math.round(totals.impressionsWoW)}%
					</span>
				</span>
			</TableCell>
			<TableCell className="text-center border-l">
				<span className="text-base">{totals.interactions}</span>
			</TableCell>
			<TableCell className="text-center border-l">
				<span className="text-base">{totals.expectedInteractions}</span>
			</TableCell>
			<TableCell className="text-center border-l">
				<span
					className={`italic font-bold ${getCompletionColor(calculateCompletion(totals.interactions, totals.expectedInteractions))}`}
				>
					{Math.round(
						calculateCompletion(
							totals.interactions,
							totals.expectedInteractions,
						),
					)}
					%
				</span>
			</TableCell>
			<TableCell className="text-center border-l">
				<span
					className={`font-bold ${
						totals.interactionsWoW > 0
							? "text-green-500"
							: totals.interactionsWoW < 0
								? "text-red-500"
								: "text-black"
					}`}
				>
					<span className="text-base">
						{totals.interactionsWoW > 0 ? "+" : ""}
						{Math.round(totals.interactionsWoW)}%
					</span>
				</span>
			</TableCell>
			<TableCell className="text-center border-l">
				<span className="text-base">{totals.comments}</span>
			</TableCell>
			<TableCell className="text-center border-l">
				<span className="text-base">{totals.expectedComments}</span>
			</TableCell>
			<TableCell className="text-center border-l">
				<span
					className={`italic font-bold ${getCompletionColor(calculateCompletion(totals.comments, totals.expectedComments))}`}
				>
					{Math.round(
						calculateCompletion(totals.comments, totals.expectedComments),
					)}
					%
				</span>
			</TableCell>
			<TableCell className="text-center border-l">
				<span
					className={`font-bold ${
						totals.commentsWoW > 0
							? "text-green-500"
							: totals.commentsWoW < 0
								? "text-red-500"
								: "text-black"
					}`}
				>
					<span className="text-base">
						{totals.commentsWoW > 0 ? "+" : ""}
						{Math.round(totals.commentsWoW)}%
					</span>
				</span>
			</TableCell>
			<TableCell className="text-center border-l" />
		</TableRow>
	);
};

const calculateWoWChange = (current, previous) => {
	if (!previous) return 0;
	return ((current - previous) / previous) * 100;
};

const getColumns = (t, usersSyncStatus) => [
	{
		accessorKey: "user_name",
		title: "Name",
		header: ({ column }) => (
			<SortableHeaderButton column={column} label="Name" />
		),
		cell: (info) => {
			return <span className="text-base">{info.getValue()}</span>;
		},
	},
	{
		header: "Posts",
		columns: [
			{
				accessorKey: "posts.actualValue",
				title: "Actual",
				header: ({ column }) => (
					<SortableHeaderButton column={column} label="Actual" />
				),
				cell: (info) => {
					const value = info.getValue();
					return (
						<span className="text-base">
							{value === "-" ? value : Math.round(value)}
						</span>
					);
				},
				sortingFn: (a, b) => {
					if (a.original.posts.actualValue === "-") return -1;
					if (b.original.posts.actualValue === "-") return 1;
					return a.original.posts.actualValue - b.original.posts.actualValue;
				},
			},
			{
				accessorKey: "posts.expectedValue",
				title: "Goal",
				header: ({ column }) => (
					<SortableHeaderButton column={column} label="Goal" />
				),
				cell: (info) => {
					return <span className="text-base">{info.getValue()}</span>;
				},
				sortingFn: (a, b) => {
					if (a.original.posts.expectedValue === "-") return -1;
					if (b.original.posts.expectedValue === "-") return 1;
					return (
						a.original.posts.expectedValue - b.original.posts.expectedValue
					);
				},
			},
			{
				accessorKey: "posts.completion",
				title: "Diff",
				header: ({ column }) => (
					<SortableHeaderButton column={column} label="Diff" />
				),
				cell: (info) => {
					const completion = info.getValue();
					return completion === "-" ? (
						<span>-</span>
					) : (
						<span
							className={`italic font-bold ${getCompletionColor(completion)}`}
						>
							{Math.round(completion)}%
						</span>
					);
				},
				sortingFn: (a, b) => {
					if (a.original.posts.completion === "-") return -1;
					if (b.original.posts.completion === "-") return 1;
					return a.original.posts.completion - b.original.posts.completion;
				},
			},
			{
				accessorKey: "postsWoW",
				title: "WoW",
				header: ({ column }) => (
					<SortableHeaderButton column={column} label="WoW" />
				),
				cell: (info) => {
					const wowChange = info.getValue();
					return wowChange === "-" ? (
						<span>-</span>
					) : (
						<span
							className={`font-bold ${
								wowChange > 0
									? "text-green-500"
									: wowChange < 0
										? "text-red-500"
										: "text-black"
							}`}
						>
							<span className="text-base">
								{wowChange > 0 ? "+" : ""}
								{Math.round(wowChange)}%
							</span>
						</span>
					);
				},
				sortingFn: (a, b) => {
					if (a.original.postsWoW === "-") return -1;
					if (b.original.postsWoW === "-") return 1;
					return a.original.postsWoW - b.original.postsWoW;
				},
			},
		],
	},
	{
		header: "Impressions",
		columns: [
			{
				accessorKey: "impressions.actualValue",
				title: "Actual",
				header: ({ column }) => (
					<SortableHeaderButton column={column} label="Actual" />
				),
				cell: (info) => {
					const value = info.getValue();
					return (
						<span className="text-base">
							{value === "-" ? value : Math.round(value)}
						</span>
					);
				},
				sortingFn: (a, b) => {
					if (a.original.impressions.actualValue === "-") return -1;
					if (b.original.impressions.actualValue === "-") return 1;
					return (
						a.original.impressions.actualValue -
						b.original.impressions.actualValue
					);
				},
			},
			{
				accessorKey: "impressions.expectedValue",
				title: "Goal",
				header: ({ column }) => (
					<SortableHeaderButton column={column} label="Goal" />
				),
				cell: (info) => {
					return <span className="text-base">{info.getValue()}</span>;
				},
				sortingFn: (a, b) => {
					if (a.original.impressions.expectedValue === "-") return -1;
					if (b.original.impressions.expectedValue === "-") return 1;
					return (
						a.original.impressions.expectedValue -
						b.original.impressions.expectedValue
					);
				},
			},
			{
				accessorKey: "impressions.completion",
				title: "Diff",
				header: ({ column }) => (
					<SortableHeaderButton column={column} label="Diff" />
				),
				cell: (info) => {
					const completion = info.getValue();
					return completion === "-" ? (
						<span>-</span>
					) : (
						<span
							className={`italic font-bold ${getCompletionColor(completion)}`}
						>
							{Math.round(completion)}%
						</span>
					);
				},
				sortingFn: (a, b) => {
					if (a.original.impressions.completion === "-") return -1;
					if (b.original.impressions.completion === "-") return 1;
					return (
						a.original.impressions.completion -
						b.original.impressions.completion
					);
				},
			},
			{
				accessorKey: "impressionsWoW",
				title: "WoW",
				header: ({ column }) => (
					<SortableHeaderButton column={column} label="WoW" />
				),
				cell: (info) => {
					const wowChange = info.getValue();
					return wowChange === "-" ? (
						<span>-</span>
					) : (
						<span
							className={`font-bold ${
								wowChange > 0
									? "text-green-500"
									: wowChange < 0
										? "text-red-500"
										: "text-black"
							}`}
						>
							<span className="text-base">
								{wowChange > 0 ? "+" : ""}
								{Math.round(wowChange)}%
							</span>
						</span>
					);
				},
				sortingFn: (a, b) => {
					if (a.original.impressionsWoW === "-") return -1;
					if (b.original.impressionsWoW === "-") return 1;
					return a.original.impressionsWoW - b.original.impressionsWoW;
				},
			},
		],
	},
	{
		header: "Interactions",
		columns: [
			{
				accessorKey: "interactions.actualValue",
				title: "Actual",
				header: ({ column }) => (
					<SortableHeaderButton column={column} label="Actual" />
				),
				cell: (info) => {
					const value = info.getValue();
					return (
						<span className="text-base">
							{value === "-" ? value : Math.round(value)}
						</span>
					);
				},
				sortingFn: (a, b) => {
					if (a.original.interactions.actualValue === "-") return -1;
					if (b.original.interactions.actualValue === "-") return 1;
					return (
						a.original.interactions.actualValue -
						b.original.interactions.actualValue
					);
				},
			},
			{
				accessorKey: "interactions.expectedValue",
				title: "Goal",
				header: ({ column }) => (
					<SortableHeaderButton column={column} label="Goal" />
				),
				cell: (info) => {
					return <span className="text-base">{info.getValue()}</span>;
				},
				sortingFn: (a, b) => {
					if (a.original.interactions.expectedValue === "-") return -1;
					if (b.original.interactions.expectedValue === "-") return 1;
					return (
						a.original.interactions.expectedValue -
						b.original.interactions.expectedValue
					);
				},
			},
			{
				accessorKey: "interactions.completion",
				title: "Diff",
				header: ({ column }) => (
					<SortableHeaderButton column={column} label="Diff" />
				),
				cell: (info) => {
					const completion = info.getValue();
					return completion === "-" ? (
						<span>-</span>
					) : (
						<span
							className={`italic font-bold ${getCompletionColor(completion)}`}
						>
							{Math.round(completion)}%
						</span>
					);
				},
				sortingFn: (a, b) => {
					if (a.original.interactions.completion === "-") return -1;
					if (b.original.interactions.completion === "-") return 1;
					return (
						a.original.interactions.completion -
						b.original.interactions.completion
					);
				},
			},
			{
				accessorKey: "interactionsWoW",
				title: "WoW",
				header: ({ column }) => (
					<SortableHeaderButton column={column} label="WoW" />
				),
				cell: (info) => {
					const wowChange = info.getValue();
					return wowChange === "-" ? (
						<span>-</span>
					) : (
						<span
							className={`font-bold ${
								wowChange > 0
									? "text-green-500"
									: wowChange < 0
										? "text-red-500"
										: "text-black"
							}`}
						>
							<span className="text-base">
								{wowChange > 0 ? "+" : ""}
								{Math.round(wowChange)}%
							</span>
						</span>
					);
				},
				sortingFn: (a, b) => {
					if (a.original.interactionsWoW === "-") return -1;
					if (b.original.interactionsWoW === "-") return 1;
					return a.original.interactionsWoW - b.original.interactionsWoW;
				},
			},
		],
	},
	{
		header: "Comments",
		columns: [
			{
				accessorKey: "comments.actualValue",
				title: "Actual",
				header: ({ column }) => (
					<SortableHeaderButton column={column} label="Actual" />
				),
				cell: (info) => {
					const value = info.getValue();
					return (
						<span className="text-base">
							{value === "-" ? value : Math.round(value)}
						</span>
					);
				},
				sortingFn: (a, b) => {
					if (a.original.comments.actualValue === "-") return -1;
					if (b.original.comments.actualValue === "-") return 1;
					return (
						a.original.comments.actualValue - b.original.comments.actualValue
					);
				},
			},
			{
				accessorKey: "comments.expectedValue",
				title: "Goal",
				header: ({ column }) => (
					<SortableHeaderButton column={column} label="Goal" />
				),
				cell: (info) => {
					return <span className="text-base">{info.getValue()}</span>;
				},
				sortingFn: (a, b) => {
					if (a.original.comments.expectedValue === "-") return -1;
					if (b.original.comments.expectedValue === "-") return 1;
					return (
						a.original.comments.expectedValue -
						b.original.comments.expectedValue
					);
				},
			},
			{
				accessorKey: "comments.completion",
				title: "Diff",
				header: ({ column }) => (
					<SortableHeaderButton column={column} label="Diff" />
				),
				cell: (info) => {
					const completion = info.getValue();
					return completion === "-" ? (
						<span>-</span>
					) : (
						<span
							className={`italic font-bold ${getCompletionColor(completion)}`}
						>
							{Math.round(completion)}%
						</span>
					);
				},
				sortingFn: (a, b) => {
					if (a.original.comments.completion === "-") return -1;
					if (b.original.comments.completion === "-") return 1;
					return (
						a.original.comments.completion - b.original.comments.completion
					);
				},
			},
			{
				accessorKey: "commentsWoW",
				title: "WoW",
				header: ({ column }) => (
					<SortableHeaderButton column={column} label="WoW" />
				),
				cell: (info) => {
					const wowChange = info.getValue();
					return wowChange === "-" ? (
						<span>-</span>
					) : (
						<span
							className={`font-bold ${
								wowChange > 0
									? "text-green-500"
									: wowChange < 0
										? "text-red-500"
										: "text-black"
							}`}
						>
							<span className="text-base">
								{wowChange > 0 ? "+" : ""}
								{Math.round(wowChange)}%
							</span>
						</span>
					);
				},
				sortingFn: (a, b) => {
					if (a.original.commentsWoW === "-") return -1;
					if (b.original.commentsWoW === "-") return 1;
					return a.original.commentsWoW - b.original.commentsWoW;
				},
			},
		],
	},
	{
		accessorKey: "user_id",
		title: t("dashboard.collaborator_metrics.status"),
		header: () => <span>{t("dashboard.collaborator_metrics.status")}</span>,
		cell: (info) => {
			const user = usersSyncStatus.find((u) => u.user_id === info.getValue());
			return user ? (
				<SyncStatusBadge
					syncStatus={user.sync_status}
					lastSyncDate={user.last_sync_date}
					lastSuccessDate={user.last_success_date}
				/>
			) : (
				<span>-</span>
			);
		},
		enableSorting: false,
	},
];

const CollaboratorMetricsTableVsGoals = ({
	weekProgressData,
	userInitialGoalMetrics,
	selectedWeek,
	goalMetrics,
	goal,
	selectedTeamId,
	t,
	usersSyncStatus,
}) => {
	const [sorting, setSorting] = useState([
		{ id: "posts_actualValue", desc: true },
	]);

	const [columnFilters, setColumnFilters] = useState([]);
	const [columnVisibility, setColumnVisibility] = useState({});

	const filteredWeekProgressData = useMemo(() => {
		return weekProgressData.filter((weekData) => weekData.week <= selectedWeek);
	}, [weekProgressData, selectedWeek]);

	const metrics = useMemo(() => {
		const userMetricsMap = {};

		filteredWeekProgressData.forEach((weekData, index) => {
			const weeksPassed = index;

			for (const metricType of ["impressions", "likes", "comments", "posts"]) {
				for (const { user_name, user_id, value } of weekData[metricType]) {
					if (!userMetricsMap[user_id]) {
						userMetricsMap[user_id] = {
							user_name,
							user_id,
							impressions: [],
							likes: [],
							comments: [],
							posts: [],
						};
					}
					userMetricsMap[user_id][metricType].push({
						week: weeksPassed,
						value,
					});
				}
			}
		});

		return Object.values(userMetricsMap).map((userMetrics) => {
			const userGoals = userInitialGoalMetrics.reduce((acc, metric) => {
				const userGoalData = metric.data.find(
					(d) => d.user_id === userMetrics.user_id,
				);
				if (userGoalData) {
					acc[metric.metricType] = {
						initialValue: userGoalData.initial_value,
						weeklyGrowthRate: metric.weeklyGrowthRate,
						startDate: userGoalData.start_date.split("T")[0],
					};
				}
				return acc;
			}, {});

			const calculateMetricValues = (metricType) => {
				const userMetricData = userMetrics[metricType];
				const latestWeekData = userMetricData[userMetricData.length - 1];
				const previousWeekData = userMetricData[userMetricData.length - 2];

				const startDate = parseISO(userGoals[metricType].startDate);
				const currentYear = new Date().getFullYear();
				const selectedWeekDate = setWeek(
					new Date(currentYear, 0, 1),
					selectedWeek,
					{ weekStartsOn: 1 },
				);

				const weeksPassed = differenceInWeeks(
					startOfWeek(selectedWeekDate, { weekStartsOn: 1 }),
					startOfWeek(startDate, { weekStartsOn: 1 }),
				);

				if (weeksPassed < 0) {
					return {
						actualValue: "-",
						expectedValue: "-",
						completion: "-",
						wowChange: "-",
					};
				}

				const expectedValue = calculateExpectedValue(
					userGoals[metricType].initialValue,
					userGoals[metricType].weeklyGrowthRate,
					weeksPassed,
				);

				const wowChange = previousWeekData
					? calculateWoWChange(latestWeekData.value, previousWeekData.value)
					: 0;

				const completion = calculateCompletion(
					latestWeekData.value,
					expectedValue,
				);

				return {
					actualValue: latestWeekData.value,
					expectedValue: Math.round(expectedValue),
					completion: Math.round(completion),
					wowChange: Math.round(wowChange),
				};
			};

			const posts = calculateMetricValues("posts");
			const impressions = calculateMetricValues("impressions");
			const likes = calculateMetricValues("likes");
			const comments = calculateMetricValues("comments");

			return {
				user_id: userMetrics.user_id,
				user_name: userMetrics.user_name,
				posts: posts,
				postsWoW: posts.wowChange,
				impressions: impressions,
				impressionsWoW: impressions.wowChange,
				interactions: likes,
				interactionsWoW: likes.wowChange,
				comments: comments,
				commentsWoW: comments.wowChange,
			};
		});
	}, [filteredWeekProgressData, userInitialGoalMetrics, selectedWeek]);

	const table = useReactTable({
		data: metrics,
		columns: getColumns(t, usersSyncStatus),
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		onColumnVisibilityChange: setColumnVisibility,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
		},
	});

	return (
		<div>
			<div className="flex items-center py-4">
				<Input
					placeholder="Filter User Names"
					value={table.getColumn("user_name")?.getFilterValue() ?? ""}
					onChange={(event) =>
						table.getColumn("user_name")?.setFilterValue(event.target.value)
					}
					className="max-w-sm"
				/>
			</div>
			<Table>
				<TableHeader>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header, headerIndex) => {
								const isFirstHeader = headerIndex === 0;
								const isLastHeader =
									headerIndex === headerGroup.headers.length - 1;

								return (
									<TableHead
										key={header.id}
										colSpan={header.colSpan}
										className={`text-center ${!isFirstHeader ? "border-l" : ""} ${!isLastHeader ? "border-r" : ""}`}
									>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext(),
												)}
									</TableHead>
								);
							})}
						</TableRow>
					))}
				</TableHeader>

				<TableBody>
					<TotalRow
						metrics={metrics}
						previousWeekData={
							filteredWeekProgressData[filteredWeekProgressData.length - 2]
						}
						selectedTeamId={selectedTeamId}
						goal={goal}
						goalMetrics={goalMetrics}
						selectedWeek={selectedWeek}
					/>
					{table.getRowModel().rows.map((row, rowIndex) => (
						<Dialog key={row.id} asChild className="min-w-[1200px]">
							<DialogTrigger asChild>
								<TableRow
									key={row.id}
									className={`hover:cursor-pointer hover:bg-muted-foreground/10 ${
										rowIndex % 2 === 0 ? "bg-gray-100" : "bg-white"
									}`}
								>
									{row.getVisibleCells().map((cell, cellIndex) => {
										const isFirstCell = cellIndex === 0;
										const isLastCell =
											cellIndex === row.getVisibleCells().length - 1;

										return (
											<TableCell
												key={cell.id}
												className={`${cell.column.id !== "user_name" ? "text-center" : ""} ${!isFirstCell ? "border-l" : ""} ${!isLastCell ? "border-r" : ""}`}
												style={
													cell.column.columnDef.cellProps
														? cell.column.columnDef.cellProps.style(cell)
														: {}
												}
											>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext(),
												)}
											</TableCell>
										);
									})}
								</TableRow>
							</DialogTrigger>
							<DialogContent className="max-w-[1300px] max-h-[80vh] overflow-y-auto">
								{/* Add detailed user analytics or any additional information here */}
							</DialogContent>
						</Dialog>
					))}
				</TableBody>
			</Table>
		</div>
	);
};

export default CollaboratorMetricsTableVsGoals;
