import { Button } from "components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { format } from "date-fns";
import { useState } from "react";
import {
	Bar,
	BarChart,
	CartesianGrid,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";
import CardSkeleton from "./card-skeleton";

interface MetricChartProps {
	selectedMetric: string;
	metrics: {
		date: string;
		linkedin: {
			posts: number;
			interactions: number;
			engagement: number;
			comments: number;
			shares: number;
			impressions: number;
			followers: number;
		};
		wordpress: {
			posts: number;
			views: number;
		};
	}[];
	loading?: boolean;
}

interface TooltipProps {
	active?: boolean;
	payload?: {
		value: number;
		name: string;
		dataKey: string;
		color: string;
	}[];
	label?: string;
}

const CustomTooltip = ({ active, payload, label }: TooltipProps) => {
	if (active && payload && payload.length) {
		return (
			<div className="bg-white p-2 border border-gray-100 rounded-md shadow-sm">
				<p className="text-xs text-gray-500">{label}</p>
				<p className="text-sm font-medium">
					{payload[0].value.toLocaleString()}
				</p>
			</div>
		);
	}
	return null;
};

export const MetricChart = ({
	selectedMetric,
	metrics,
	loading,
}: MetricChartProps) => {
	const [chartType, setChartType] = useState<"bar" | "line">("line");

	const getMetricValue = (metric: MetricChartProps["metrics"][0]) => {
		switch (selectedMetric.toLowerCase()) {
			case "followers":
				return metric.linkedin.followers;
			case "posts":
				return metric.linkedin.posts;
			case "interactions":
				return metric.linkedin.interactions;
			case "engagement":
				return metric.linkedin.engagement;
			case "comments":
				return metric.linkedin.comments;
			case "shares":
				return metric.linkedin.shares;
			case "impressions":
				return metric.linkedin.impressions;
			case "blogs":
				return metric.wordpress.posts;
			default:
				return 0;
		}
	};

	const chartData = metrics.map((metric) => ({
		date: format(new Date(metric.date), "MMM dd"),
		value: getMetricValue(metric),
	}));

	const commonProps = {
		data: chartData,
		margin: { top: 5, right: 20, left: 5, bottom: 5 },
	};

	const commonAxisProps = {
		tickLine: false,
		axisLine: false,
	};

	if (loading) {
		return <CardSkeleton />;
	}

	return (
		<Card className="w-full bg-white">
			<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
				<CardTitle className="text-xl font-semibold">
					{selectedMetric} Over Time
				</CardTitle>
				<div className="flex gap-1">
					<Button
						variant={chartType === "line" ? "secondary" : "outline"}
						size="sm"
						className="h-7 text-xs px-3"
						onClick={() => setChartType("line")}
					>
						Line
					</Button>
					<Button
						variant={chartType === "bar" ? "secondary" : "outline"}
						size="sm"
						className="h-7 text-xs px-3"
						onClick={() => setChartType("bar")}
					>
						Bar
					</Button>
				</div>
			</CardHeader>
			<CardContent>
				<div className="h-[260px]">
					<ResponsiveContainer width="100%" height="100%">
						{chartType === "bar" ? (
							<BarChart {...commonProps}>
								<CartesianGrid
									strokeDasharray="3 3"
									vertical={false}
									stroke="#f5f5f5"
								/>
								<XAxis
									dataKey="date"
									{...commonAxisProps}
									padding={{ left: 10, right: 10 }}
								/>
								<YAxis
									{...commonAxisProps}
									tickFormatter={(value) => value.toLocaleString()}
									width={35}
								/>
								<Tooltip content={<CustomTooltip />} />
								<Bar
									dataKey="value"
									fill="hsl(var(--primary))"
									radius={[1, 1, 0, 0]}
									maxBarSize={35}
									opacity={0.9}
								/>
							</BarChart>
						) : (
							<LineChart {...commonProps}>
								<CartesianGrid
									strokeDasharray="3 3"
									vertical={false}
									stroke="#f5f5f5"
								/>
								<XAxis
									dataKey="date"
									{...commonAxisProps}
									padding={{ left: 10, right: 10 }}
								/>
								<YAxis
									{...commonAxisProps}
									tickFormatter={(value) => value.toLocaleString()}
									width={35}
								/>
								<Tooltip content={<CustomTooltip />} />
								<Line
									type="monotone"
									dataKey="value"
									stroke="hsl(var(--primary))"
									strokeWidth={1.5}
									dot={{ r: 1.5, strokeWidth: 1 }}
									activeDot={{ r: 3, strokeWidth: 0 }}
								/>
							</LineChart>
						)}
					</ResponsiveContainer>
				</div>
			</CardContent>
		</Card>
	);
};
