import { Card, CardContent } from "components/ui/card";
import { cn } from "lib/utils";
import { Check } from "lucide-react";
import { useState } from "react";
import type { LinkedInPost } from "../types";

type LinkedInPostCardProps = {
	post: LinkedInPost;
	isSelected: boolean;
	onSelect: () => void;
	disabled?: boolean;
};

export const LinkedInPostCard = ({
	post,
	isSelected,
	onSelect,
	disabled,
}: LinkedInPostCardProps) => {
	const [isExpanded, setIsExpanded] = useState(false);

	const handleViewMoreClick = (e: React.MouseEvent) => {
		e.stopPropagation();
		setIsExpanded(!isExpanded);
	};

	return (
		<Card
			onClick={disabled && !isSelected ? undefined : onSelect}
			className={cn(
				"w-full transition-colors",
				"hover:bg-accent hover:text-accent-foreground",
				isSelected && "border-primary bg-accent",
				disabled && !isSelected && "opacity-50 cursor-not-allowed",
				!disabled && "cursor-pointer",
			)}
		>
			<CardContent className="p-2">
				<p className={cn("text-xs", !isExpanded && "line-clamp-2 mb-1")}>
					{post.content}
				</p>
				{post.content.length > 100 && (
					<button
						type="button"
						onClick={handleViewMoreClick}
						className="text-xs text-primary hover:underline mt-1"
					>
						{isExpanded ? "View less" : "View more"}
					</button>
				)}
				<div className="flex items-center justify-between mt-2">
					<div className="flex gap-2 text-xs text-muted-foreground">
						<span>{post.impressions.toLocaleString()} 👁️</span>
						<span>{post.likes.toLocaleString()} 👍</span>
						<span>{post.comments.toLocaleString()} 💬</span>
						<span>{post.shares.toLocaleString()} 🔄</span>
					</div>
					{isSelected && <Check className="h-4 w-4 flex-shrink-0" />}
				</div>
			</CardContent>
		</Card>
	);
};
