import { AuthContext } from "components/lib";
import { Button } from "components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Input } from "components/ui/input";
import { Switch } from "components/ui/switch";
import { RefreshCcw } from "lucide-react";
import { Settings } from "lucide-react";
import { useContext, useState, useEffect } from "react";
import type { AuthContextType } from "types/authContext";
import { useIcpForm } from "../_hooks/use-icp-form";
import { useLeadTrackingState } from "../_hooks/use-lead-tracking-state";
import { useUserLeads } from "../_hooks/use-user-leads";
import { useUserPosts } from "../_hooks/use-user-posts";
import { leadLensApi } from "../services/lead-lens-api";
import type { Post, PostInteraction, PostReactionInteraction } from "../types";
import { ErrorState } from "./error-state";
import { IcpSettingsDialog } from "./icp-settings-dialog";
import { LeadProfileDialog } from "./lead-profile-dialog";
import { LeadTrackingTableSkeleton } from "./lead-tracking-skeleton";
import { LeadsPerPostTable } from "./leads-per-post-table";
import { LeadsTable } from "./leads-table/component";
import { PostLeadsDialog } from "./post-leads-dialog";
import { TooltipProvider } from "components/ui/tooltip";
import { useDebounce } from 'hooks/use-debounce';
import { useLeadLensEvents } from "../events";

export const LeadTrackingTable = () => {
	const auth = useContext<AuthContextType>(AuthContext);
	const state = useLeadTrackingState();
	const events = useLeadLensEvents();
	const { form, settingsLoading, handleSubmit } = useIcpForm(
		auth.user?.id || "", 
		auth.user?.token || ""
	);

	const [postsSortBy, setPostsSortBy] = useState('creation_time');
	const [leadsSortBy, setLeadsSortBy] = useState('icpScore');
	const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

	const [postSearchTerm, setPostSearchTerm] = useState('');
	const debouncedPostSearchTerm = useDebounce(postSearchTerm, 300);

	const [leadSearchTerm, setLeadSearchTerm] = useState('');
	const debouncedLeadSearchTerm = useDebounce(leadSearchTerm, 300);

	const sortBy = state.showLeadsPerPost ? postsSortBy : leadsSortBy;

	const {
		data: postsData,
		loading: postsLoading,
		error: postsError,
		refetch: postsRefetch,
  } = useUserPosts(
		auth?.user?.id, 
		state.page, 
		state.limit, 
		postsSortBy,
		sortDirection,
		debouncedPostSearchTerm
	);

	const {
		data: leadsData,
		loading: leadsLoading,
		error: leadsError,
		refetch: leadsRefetch,
	} = useUserLeads(
		auth?.user?.id,
		state.page,
		state.limit,
		leadsSortBy,
		sortDirection,
		debouncedLeadSearchTerm
	);

	const handleGenerateIcebreaker = async (linkedinId: string) => {
		try {
			state.setSyncingProfiles((prev) => new Set(prev).add(linkedinId));
			events.trackIcebreakerGeneration({
				linkedin_public_profile_id: parseInt(linkedinId),
				linkedin_profile_id: auth.user?.id || "",
			});
			await leadLensApi.generateIcebreaker({
				linkedinId,
				userId: auth.user?.id || "",
				token: auth.user?.token || "",
			});
			await leadsRefetch();
		} catch (error) {
			console.error("Error generating icebreaker:", error);
		} finally {
			state.setSyncingProfiles((prev) => {
				const newSet = new Set(prev);
				newSet.delete(linkedinId);
				return newSet;
			});
		}
	};

	const handleSyncLeads = async (postId: string) => {
		try {
			state.addSyncingPost(postId);
			events.trackPostSync({
				linkedin_id: postId,
				content: "", // Add content if available
				last_lead_sync: new Date().toISOString(),
			});
			const result = await leadLensApi.syncPostInteractions({
				postId,
				userId: auth.user?.id || "",
				token: auth.user?.token || "",
			});
			if (result?.data) {
				await postsRefetch();
				await leadsRefetch();
			}
		} catch (error) {
			console.error("Error syncing post interactions:", error);
		} finally {
			state.removeSyncingPost(postId);
		}
	};

	const handleViewToggle = (checked: boolean) => {
		events.trackViewToggle({
			view_type: checked ? "leads_per_post" : "all_leads",
		});
		state.setShowLeadsPerPost(checked);
		state.setPage(1);
		if (checked) {
			setLeadSearchTerm('');
			postsRefetch();
		} else {
			setPostSearchTerm('');
			leadsRefetch();
		}
	};

	const handleRowClick = (item: Post | PostInteraction) => {
		if ("qualifiedLeads" in item) {
			state.setSelectedPost(item as Post);
		} else if ("profile" in item) {
			const lead = item as PostInteraction;
			events.trackProfileView({
				linkedin_id: lead.profile.linkedinId,
				first_name: lead.profile.firstName,
				last_name: lead.profile.lastName,
				company: lead.profile.company || "",
				title: lead.profile.title || "",
				icp_score: lead.profile.lead?.icpScore || 0,
				is_qualified: lead.profile.lead?.isQualified || false,
			});
			state.setSelectedLead(lead);
		}
	};

	const handlePageSizeChange = (newSize: number) => {
		events.trackPageSizeChange({
			old_size: state.limit,
			new_size: newSize,
			view_type: state.showLeadsPerPost ? "leads_per_post" : "all_leads",
		});
		state.setLimit(newSize);
		state.setPage(1);
	};

	const handleSortChange = (newSortBy: string, newSortDirection: 'asc' | 'desc') => {
		const isNewColumn = sortBy !== newSortBy;
		const direction = isNewColumn ? 'desc' : newSortDirection;
		
		events.trackTableSort({
			sort_by: newSortBy,
			sort_direction: direction,
			view_type: state.showLeadsPerPost ? "leads_per_post" : "all_leads",
		});
		
		if (state.showLeadsPerPost) {
			setPostsSortBy(newSortBy);
			postsRefetch();
		} else {
			setLeadsSortBy(newSortBy);
				leadsRefetch();
		}
		setSortDirection(direction);
		state.setPage(1);
	};

	// Add search event tracking
	useEffect(() => {
		if (debouncedLeadSearchTerm || debouncedPostSearchTerm) {
			events.trackLeadSearch({
				search_term: debouncedLeadSearchTerm || debouncedPostSearchTerm,
				results_count: state.showLeadsPerPost ? postsData?.total || 0 : leadsData?.total || 0,
				view_type: state.showLeadsPerPost ? "leads_per_post" : "all_leads",
			});
		}
	}, [debouncedLeadSearchTerm, debouncedPostSearchTerm]);

	if (postsLoading || leadsLoading) {
		return <LeadTrackingTableSkeleton />;
	}

	if (postsError || leadsError) {
		return (
			<ErrorState 
				message={postsError?.message || leadsError?.message || "Failed to load data"} 
				onRetry={() => {
					postsRefetch();
					leadsRefetch();
				}}
			/>
		);
	}

	const hasIcpSettings = form.getValues("icp_description") || 
							form.getValues("icp_target_industries") ||
							form.getValues("icp_target_roles");

	if (!hasIcpSettings) {
		return (
			<Card className="bg-white">
				<CardHeader>
					<CardTitle>Lead Tracking</CardTitle>
				</CardHeader>
				<CardContent className="flex flex-col items-center justify-center py-12 text-center">
					<div className="rounded-full bg-muted p-4 mb-4">
						<Settings className="h-8 w-8 text-muted-foreground" />
					</div>
					<h3 className="text-lg font-semibold mb-2">ICP Settings Required</h3>
					<p className="text-muted-foreground mb-6 max-w-md">
						To start tracking and qualifying leads, you need to configure your Ideal Customer Profile (ICP) settings first.
					</p>
					<IcpSettingsDialog 
						userId={auth.user?.id || ""} 
						token={auth.user?.token || ""} 
					/>
				</CardContent>
			</Card>
		);
	}

	return (
    <TooltipProvider>
      <Card className="bg-white">
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle>Lead Tracking</CardTitle>
            <div className="flex items-center gap-4">
              <div className="flex items-center space-x-2">
                <Switch
                  id="show-leads-per-post"
                  checked={state.showLeadsPerPost}
                  onCheckedChange={handleViewToggle}
                />
                <label htmlFor="show-leads-per-post">Show Leads per post</label>
              </div>
              <Button onClick={() => postsRefetch()} disabled={postsLoading}>
                <RefreshCcw
                  className={`w-4 h-4 mr-2 ${postsLoading ? "animate-spin" : ""}`}
                />
                {postsLoading ? "Refreshing..." : "Refresh"}
              </Button>
              <IcpSettingsDialog 
                userId={auth.user?.id || ""} 
                token={auth.user?.token || ""} 
              />
            </div>
          </div>
        </CardHeader>
        <CardContent>
          <div className="flex justify-between items-center mb-4">
            <Input
              type="text"
              placeholder={state.showLeadsPerPost ? "Search posts..." : "Search leads..."}
              value={state.showLeadsPerPost ? postSearchTerm : leadSearchTerm}
              onChange={(e) => {
                if (state.showLeadsPerPost) {
                  setPostSearchTerm(e.target.value);
                } else {
                  setLeadSearchTerm(e.target.value);
                }
              }}
              className="max-w-sm"
            />
          </div>

          <div className="rounded-md border">
            {state.showLeadsPerPost ? (
              <LeadsPerPostTable
                posts={postsData?.posts || []}
                onPostClick={handleRowClick}
                onSyncLeads={handleSyncLeads}
                currentPage={state.page}
                totalPages={postsData?.totalPages || 1}
                totalItems={postsData?.totalItems || 0}
                onPageChange={state.setPage}
                pageSize={state.limit}
                onPageSizeChange={handlePageSizeChange}
                syncingPosts={state.syncingPosts}
                sortBy={sortBy}
                sortDirection={sortDirection}
                onSortChange={handleSortChange}
              />
            ) : (
              <LeadsTable
                leads={leadsData?.leads as PostReactionInteraction[] || []}
                onLeadClick={handleRowClick}
                onGenerateIcebreaker={handleGenerateIcebreaker}
                syncingProfiles={state.syncingProfiles}
                currentPage={state.page}
                totalPages={leadsData?.totalPages || 1}
                onPageChange={state.setPage}
                pageSize={state.limit}
                onPageSizeChange={handlePageSizeChange}
                totalItems={leadsData?.totalItems || 0}
                sortBy={sortBy}
                sortDirection={sortDirection}
                onSortChange={handleSortChange}
              />
            )}
          </div>
        </CardContent>

        <LeadProfileDialog
          interaction={state.selectedLead}
          onClose={() => state.setSelectedLead(null)}
          onGenerateIcebreaker={handleGenerateIcebreaker}
          isSyncing={state.syncingProfiles.has(state.selectedLead?.profile.linkedinId || '')}
        />
        <PostLeadsDialog
          post={state.selectedPost}
          onGenerateIcebreaker={handleGenerateIcebreaker}
          onClose={() => state.setSelectedPost(null)}
          refetchPosts={postsRefetch}
          syncingProfiles={state.syncingProfiles}
          setSyncingProfiles={state.setSyncingProfiles}
          />
      </Card>
		</TooltipProvider>
	);
}; 