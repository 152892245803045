import { Badge } from "components/ui/badge";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "components/ui/dialog";
import {
	Building2,
	LinkedinIcon,
	MapPin,
	MessageSquare,
	RefreshCcw,
	Trophy,
	Users,
} from "lucide-react";
import type { PostInteraction, PostReactionInteraction } from "../types";
import { Button } from "components/ui/button";

interface LeadProfileDialogProps {
	interaction: PostInteraction | PostReactionInteraction | null;
	onClose: () => void;
	onGenerateIcebreaker: (linkedinId: string) => Promise<void>;
	isSyncing: boolean;
}

export const LeadProfileDialog = ({
	interaction,
	onClose,
	onGenerateIcebreaker,
	isSyncing,
}: LeadProfileDialogProps) => {
	if (!interaction) return null;
	const { profile, interactions } = interaction;

	const getInteractionColor = (type: string | null) => {
		switch (type) {
			case "like":
				return "text-blue-500";
			case "comment":
				return "text-green-500";
			case "share":
				return "text-purple-500";
			default:
				return "text-muted-foreground";
		}
	};

	const getInteractionIcon = (type: string | null) => {
		switch (type) {
			case "like":
				return <LinkedinIcon className="w-3 h-3" />;
			case "comment":
				return <MessageSquare className="w-3 h-3" />;
			case "share":
				return <RefreshCcw className="w-3 h-3" />;
			default:
				return null;
		}
	};

	const renderLeadInsights = () => {
		if (!profile.lead?.engagementFactors && !profile.lead?.icpAlignment && !profile.lead?.qualificationReason) {
			return null;
		}

		return (
			<Card>
				<CardHeader className="p-4 pb-2">
					<CardTitle className="text-lg font-medium flex items-center gap-2">
						<Trophy className="w-4 h-4" />
						Lead Insights
					</CardTitle>
				</CardHeader>
				<CardContent className="p-4 pt-0">
					<div className="space-y-4">
						{profile.lead.engagementFactors && (
							<div className="space-y-1">
								<h4 className="text-sm font-semibold">Engagement Factors</h4>
								<p className="text-sm text-muted-foreground">{profile.lead.engagementFactors}</p>
							</div>
						)}
						{profile.lead.icpAlignment && (
							<div className="space-y-1">
								<h4 className="text-sm font-semibold">ICP Alignment</h4>
								<p className="text-sm text-muted-foreground">{profile.lead.icpAlignment}</p>
							</div>
						)}
						{profile.lead.qualificationReason && (
							<div className="space-y-1">
								<h4 className="text-sm font-semibold">Qualification</h4>
								<p className="text-sm text-muted-foreground">{profile.lead.qualificationReason}</p>
							</div>
						)}
					</div>
				</CardContent>
			</Card>
		);
	};

	return (
		<Dialog open={!!interaction} onOpenChange={onClose}>
			<DialogContent className="sm:max-w-[600px] max-h-[90vh] overflow-y-auto">
				<DialogHeader>
					<DialogTitle className="text-2xl">Lead Profile</DialogTitle>
				</DialogHeader>

				<div className="flex flex-col gap-4">
					{/* Profile Header */}
					<Card>
						<CardContent className="pt-6">
							<div className="flex flex-col gap-4">
								<div className="flex items-start gap-4">
									<img
										src={profile.profileImageUrl || "/placeholder.svg"}
										alt={`${profile.firstName} ${profile.lastName}`}
										className="w-20 h-20 rounded-full object-cover border-2 border-border"
									/>
									<div className="flex-1">
										<h3 className="text-xl font-semibold">
											{profile.firstName} {profile.lastName}
										</h3>
										<p className="text-sm text-muted-foreground mb-2">
											{profile.headline || "No bio available"}
										</p>
										<div className="flex flex-wrap gap-2">
											<Badge
												variant="outline"
													className="flex items-center gap-1"
											>
												<Building2 className="w-3 h-3" />
												{profile.company || "No company"}
											</Badge>
											<Badge
												variant="outline"
													className="flex items-center gap-1"
											>
												<MapPin className="w-3 h-3" />
												{profile.locationName || "No location"}
											</Badge>
										</div>
									</div>
								</div>
								<Button
									variant="outline"
									className="w-full flex items-center justify-center gap-2 text-[#0A66C2] hover:text-[#0A66C2]/90 hover:bg-[#0A66C2]/10"
									onClick={() => window.open(`https://www.linkedin.com/in/${profile.linkedinId}`, '_blank')}
									>
									<LinkedinIcon className="h-4 w-4" />
									View on LinkedIn
								</Button>
							</div>
						</CardContent>
					</Card>

					{renderLeadInsights()}

					{/* Metrics */}
					<div className="grid grid-cols-2 gap-4">
						<Card>
							<CardHeader className="p-4">
								<CardTitle className="text-base font-medium flex items-center gap-2">
									<Users className="w-4 h-4" />
									Engagement Score
								</CardTitle>
							</CardHeader>
							<CardContent className="pt-0 px-4 pb-4">
								<div className="text-2xl font-bold">
									{interactions.count || "0"}
								</div>
							</CardContent>
						</Card>

						<Card>
							<CardHeader className="p-4">
								<CardTitle className="text-base font-medium flex items-center gap-2">
									<Trophy className="w-4 h-4" />
									ICP Score
								</CardTitle>
							</CardHeader>
							<CardContent className="pt-0 px-4 pb-4">
								<div className="text-2xl font-bold">
									{profile.lead?.icpScore || "N/A"}
								</div>
							</CardContent>
						</Card>
					</div>

					{/* Interaction History */}
					<Card>
						<CardHeader className="p-4 pb-2">
							<CardTitle className="text-lg font-medium">
								Interaction History
							</CardTitle>
						</CardHeader>
						<CardContent className="p-4 pt-0">
							<div className="space-y-4 max-h-[280px] overflow-y-auto pr-2">
								{[...interactions.posts]
									.sort((a, b) => {
										const dateA = a.interactionDate
											? new Date(a.interactionDate).getTime()
											: 0;
										const dateB = b.interactionDate
											? new Date(b.interactionDate).getTime()
											: 0;
										return dateB - dateA;
									})
									.map((post) => (
										<div key={post.id} className="p-3 bg-muted rounded-lg">
											<div className="flex items-center justify-between mb-2">
												<span className="text-sm font-medium">
													{post.interactionDate
														? new Date(
																post.interactionDate,
															).toLocaleDateString()
														: "No date"}
												</span>
												<Badge
													variant="secondary"
													className={`flex items-center gap-1 ${getInteractionColor(post.type)}`}
												>
													{getInteractionIcon(post.type)}
													<span>
														{post.type === "like" && "Liked post"}
														{post.type === "comment" && "Commented"}
														{post.type === "share" && "Shared post"}
													</span>
												</Badge>
											</div>
											{post.content && (
												<div className="mb-2 p-2 bg-background rounded border">
													<p className="text-sm text-muted-foreground line-clamp-2">
														{post.content}
													</p>
												</div>
											)}
										</div>
									))}
							</div>
						</CardContent>
					</Card>

					{/* Icebreaker */}
					{profile.lead?.icebreaker && (
						<Card>
							<CardHeader className="p-4 pb-2">
								<CardTitle className="text-sm font-medium">
									Icebreaker Suggestion
								</CardTitle>
							</CardHeader>
							<CardContent className="p-4 pt-0">
								<p className="text-sm text-muted-foreground">
									{profile.lead.icebreaker}
								</p>
							</CardContent>
						</Card>
					)}
				</div>
			</DialogContent>
		</Dialog>
	);
}; 